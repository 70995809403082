import React, { ReactNode, useEffect } from 'react'

import { useConfig } from '@guiker/config-context'
import {
  BeforeValidation,
  FormProvider,
  ReadOnlyForm,
  useForm,
  useFormContext,
  UseFormReturn,
  yupResolver,
} from '@guiker/react-hook-form'
import { yup } from '@guiker/shared-framework'

import { ContextConsumerNode } from '../../types'
import { useFormEvent } from '../../utils'
import { ApiFormContextProvider } from './ApiFormContext'
import { BaseForm, BaseFormProps } from './BaseForm'

export type ApiFormProps<TFormValues, TResult, TContext> = Omit<
  BaseFormProps<TFormValues, TResult, TContext>,
  'formOptions'
> & {
  beforeValidation?: BeforeValidation<TFormValues>
  formName: string
  skipIfIsNotDirty?: boolean
  formOptions?: BaseFormProps<TFormValues, TResult, TContext>['formOptions'] & { schema?: yup.Schema<TFormValues> }
  formMethods?: UseFormReturn<TFormValues>
  readOnly?: boolean
  children?: ReactNode | ContextConsumerNode<TResult>
}

export const ApiForm = <TResult, TContext, TFormValues extends Record<string, any> = Record<string, any>>({
  ...props
}: ApiFormProps<TFormValues, TResult, TContext>) => {
  const formMethods = useFormContext<TFormValues>()

  if (props.readOnly) {
    return <ReadOnlyForm {...props?.formOptions}>{props.children as any}</ReadOnlyForm>
  } else if (formMethods) {
    return <WrappedApiForm formMethods={formMethods} {...props} />
  } else {
    return <NotInitializedApiForm {...props} />
  }
}

const NotInitializedApiForm = <TResult, TContext, TFormValues extends Record<string, any> = Record<string, any>>({
  formOptions,
  ...props
}: ApiFormProps<TFormValues, TResult, TContext>) => {
  const { reValidateMode = 'onBlur', mode = 'onSubmit', ...otherFormOptions } = formOptions || {}
  const formMethods = useForm<TFormValues>({
    shouldUnregister: false,
    ...otherFormOptions,
    mode,
    reValidateMode,
    resolver: formOptions?.resolver
      ? formOptions?.resolver
      : formOptions?.schema
      ? yupResolver(formOptions?.schema, { stripUnknown: true })
      : undefined,
  })

  return <WrappedApiForm formMethods={formMethods} schema={formOptions?.schema} {...props} />
}

const WrappedApiForm = <TResult, TContext, TFormValues extends Record<string, any> = Record<string, any>>({
  beforeValidation,
  onSubmit,
  formMethods,
  apiOptions = {},
  readOnly = false,
  skipIfIsNotDirty = true,
  formName,
  schema,
  ...props
}: ApiFormProps<TFormValues, TResult, TContext> & { readOnly?: boolean; schema?: yup.Schema<TFormValues> }) => {
  const { sendFormEvent } = useFormEvent(formName)
  const { logger } = useConfig()
  const {
    formState: { submitCount, errors },
  } = formMethods

  useEffect(() => {
    if (submitCount >= 1 && errors && Object.keys(errors).length > 0) {
      try {
        const formErrorDetails = JSON.stringify(errors)
        sendFormEvent({ event: 'formError', formName, formErrorDetails })
      } catch {
        logger.log(errors)
      }
    }
  }, [submitCount, errors, formName])

  useEffect(() => {
    sendFormEvent({ event: 'formStart', formName })
  }, [formName])

  return (
    <FormProvider {...formMethods} schema={schema} beforeValidation={beforeValidation} {...props}>
      <ApiFormContextProvider
        formName={formName}
        readOnly={readOnly}
        onSubmit={onSubmit}
        skipIfIsNotDirty={skipIfIsNotDirty}
        apiOptions={apiOptions}
      >
        <BaseForm apiOptions={apiOptions} onSubmit={onSubmit} {...props} />
      </ApiFormContextProvider>
    </FormProvider>
  )
}
