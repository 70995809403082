import React from 'react'

import { TextField, TextFieldProps as CLTextFieldProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, ErrorMessages, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type TextFieldProps<T extends object, P extends FieldPath<T>> = Omit<CLTextFieldProps, 'name' | 'defaultValue'> &
  ErrorMessages & {
    name: P
    defaultValue?: PathValue<T, P>
  }

const RHFTextField = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  name,
  onChange,
  errorMessages = {},
  required: propsRequired,
  ...props
}: TextFieldProps<T, P>) => {
  const { t } = useTranslation()
  const { errorType, errorMessage, control, required } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange: onChangeRenderProps, ref, ...renderProps }, fieldState }) => (
        <TextField
          onChange={(event) => {
            onChange && onChange(event)
            onChangeRenderProps(event)
          }}
          error={!!fieldState.error}
          errorMessage={errorMessages[errorType] || (fieldState.error && errorMessage)}
          inputRef={ref}
          required={propsRequired ?? required}
          {...renderProps}
          {...props}
        />
      )}
    />
  )
}

export { RHFTextField }
