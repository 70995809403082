import React, { useMemo } from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, toPx } from '@guiker/components-core'
import { isArray } from '@guiker/shared-framework'
import { default as MuiSlider, Mark, SliderProps as MuiSliderProps } from '@material-ui/core/Slider'

import { Box, Flex, TextField } from '../../..'
import { BaseTooltip, Label, PBold } from '../../Data Display'
import { Input, InputProps } from '../Input'
import { SliderFormatter } from './formatter'

export type SliderProps = InputProps &
  Omit<MuiSliderProps, 'color' | 'marks'> & {
    maxWidth?: string | number
    label?: string
    description?: string
    helperText?: string
    error?: boolean
    errorMessage?: string
    value?: number
    formatter?: SliderFormatter
    withValueLabel?: boolean
    width?: string | number
    marks?: Mark[]
    withTextField?: boolean
    textFieldEndAdornment?: React.ReactNode
  }

const defaultFormatter: SliderFormatter = {
  toDisplay: (value: string) => Number(value),
  toActual: (value: string) => Number(value),
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& .MuiSlider-thumb': {
        height: toPx(theme.spacing(2)),
        width: toPx(theme.spacing(2)),
        marginTop: -4,
        marginLeft: -8,
      },
    },
    valueLabel: {
      marginLeft: 2,
    },
    mark: {
      height: theme.spacing(3),
      width: 1,
      top: 4,
      marginBottom: 8,
      backgroundColor: 'transparent',
      borderRadius: 0,
      borderLeft: `1px dashed ${theme.palette.grey[30]}`,
      opacity: 1,
    },
    markActive: {
      opacity: 1,
    },
    markLabel: ({ markLength }: { markLength: number }) => ({
      color: theme.palette.text.primary.main,
      marginTop: theme.spacing(1),
      fontWeight: theme.typography.variants.body.fontWeight,
      fontFamily: theme.typography.fontFamilies.body.normal,
      transform: 'translateX(-50%)',
      '&[data-index="0"]': {
        transform: 'translateX(0%)',
      },
      [`&[data-index="${markLength}"]`]: {
        transform: 'translateX(-100%)',
      },
    }),
    thumb: {
      height: toPx(theme.spacing(2)),
      width: toPx(theme.spacing(2)),
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      '&:focus, &:hover': {
        boxShadow: 'none',
      },
    },
    rail: {
      height: toPx(theme.spacing(1)),
      color: theme.palette.grey[15],
    },
    track: {
      height: toPx(theme.spacing(1)),
      color: theme.palette.primary.main,
    },
  }),
  {
    name: 'Slider',
  },
)

type ValueLabelProps = {
  children: React.ReactElement
  open: boolean
  value: number
  formatter: SliderFormatter
}

const ValueLabel = (props: ValueLabelProps) => {
  const { children, open, value, formatter } = props

  return (
    <BaseTooltip open={open} enterTouchDelay={0} placement='bottom' title={formatter.toDisplay(value)}>
      {children}
    </BaseTooltip>
  )
}

const Slider: React.FC<SliderProps> = (props) => {
  const {
    className,
    maxWidth,
    marks = [],
    label,
    error,
    errorMessage,
    description,
    disabled,
    helperText,
    name,
    min = 0,
    max = 100,
    valueLabelDisplay = 'off',
    value,
    formatter = defaultFormatter,
    withValueLabel = true,
    defaultValue,
    width,
    textFieldEndAdornment,
    withTextField = false,
    ...other
  } = props
  const classes = useStyles({ markLength: marks.length + 1 })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      other?.onChange?.(event, null)
      return
    }

    const newValue = Number(event.target.value)
    other?.onChange?.(event, newValue)
  }

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value)
    other?.onChange?.(event, newValue > max ? max : newValue < min ? min : newValue)
  }

  const sanizitedMarks = useMemo(() => {
    return isArray(marks)
      ? marks
          .filter(({ value }) => value !== min && value !== max)
          .map(({ value, label }) => ({ value, label: label || formatter.toDisplay(value) }))
      : []
  }, [])

  return (
    <Input
      className={className}
      error={error}
      errorMessage={errorMessage}
      value={(value as unknown as number) ?? defaultValue ?? min}
      {...props}
      label={
        <Flex justifyContent='space-between' alignItems='center' width={'100%'}>
          <Label text={label} description={description} disabled={disabled} />
          {withTextField && (
            <TextField
              width={140}
              condensed
              onBlur={onBlur}
              defaultValue={defaultValue}
              name={name}
              value={value !== undefined ? value : defaultValue ?? min}
              onChange={handleInputChange}
              type='number'
              endAdornment={textFieldEndAdornment}
            />
          )}
          {withValueLabel && !withTextField && isArray(value) ? (
            <Flex gap={0.5}>
              <PBold mb={0} whiteSpace='nowrap'>
                {formatter.toDisplay(value[0])}
              </PBold>
              <span>-</span>
              <PBold mb={0} whiteSpace='nowrap'>
                {formatter.toDisplay(value[1])}
              </PBold>
            </Flex>
          ) : !withTextField ? (
            <PBold mb={0} whiteSpace='nowrap'>
              {formatter.toDisplay(value ?? min)}
            </PBold>
          ) : undefined}
        </Flex>
      }
    >
      <Box mx={1}>
        <MuiSlider
          className={clsx(classes.root, className)}
          disabled={disabled}
          classes={{
            root: classes.root,
            thumb: classes.thumb,
            rail: classes.rail,
            track: classes.track,
            valueLabel: classes.valueLabel,
            mark: classes.mark,
            markActive: classes.markActive,
            markLabel: classes.markLabel,
          }}
          min={min}
          max={max}
          valueLabelDisplay={valueLabelDisplay}
          ValueLabelComponent={
            valueLabelDisplay !== 'off' ? (props) => <ValueLabel {...props} formatter={formatter} /> : undefined
          }
          name={name}
          defaultValue={defaultValue}
          value={value ?? defaultValue}
          marks={[
            {
              value: min,
              label: formatter.toDisplay(min),
            },
            ...sanizitedMarks,
            {
              value: max,
              label: formatter.toDisplay(max),
            },
          ]}
          {...other}
        />
      </Box>
    </Input>
  )
}

export { Slider }
