import React from 'react'

import { Autocomplete, BaseAutocompleteProps as CLAutocompleteProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, FieldPath, PathValue, useFormContext, useFormInput } from '@guiker/react-hook-form'

export type RHFAutocompleteProps<T extends object, P extends FieldPath<T>> = Omit<
  CLAutocompleteProps,
  'name' | 'defaultValue'
> & {
  name: P
  defaultValue?: PathValue<T, P>
}

export const RHFAutocomplete = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  name,
  onChange,
  required: propsRequired,
  ...props
}: RHFAutocompleteProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorMessage, required } = useFormInput<T, P>({ name, t })
  const { setValue } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange: onChangeRenderProps, ref, value, ...renderProps }, fieldState }) => (
        <Autocomplete
          name={name as string}
          value={value}
          onChange={(event: any, newValue: any) => {
            onChange && onChange(event, newValue)
            onChangeRenderProps(event, newValue)
            setValue(name, newValue)
          }}
          error={!!fieldState.error}
          errorMessage={fieldState.error && errorMessage}
          required={propsRequired ?? required}
          {...renderProps}
          {...props}
        />
      )}
    />
  )
}
