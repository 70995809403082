import { DataLayerEvent, useDataTrackingContext } from '@guiker/data-tracking-context'
import { useSentryContext } from '@guiker/sentry-context'

const ERROR_TYPES_REPORTED_AS_MESSAGE = ['ApiError']

export const useFormEvent = (formName: string) => {
  const { sendEvent } = useDataTrackingContext()
  const { captureMessage, captureException } = useSentryContext({ shouldThrowIfUndefined: false })

  const captureFormError = (formErrorDetails: string, details?: object) => {
    captureMessage?.({
      message: `FormError:${formName}`,
      trusted: details ? `${formErrorDetails} \n ${JSON.stringify(details)}` : formErrorDetails,
    })
  }

  const sendFormEvent = (event: DataLayerEvent, details?: object) => {
    formName && sendEvent(event)
    event.event === 'formError' && captureFormError(event.formErrorDetails, details)
  }

  const handleFormError = (error: Error, details?: object) => {
    if (ERROR_TYPES_REPORTED_AS_MESSAGE.includes(error.name)) {
      const formErrorDetails = `${error.name}: ${error.message}`
      sendFormEvent({ event: 'formError', formName, formErrorDetails })
      captureFormError(formErrorDetails)
    } else {
      captureException({ error, errorInfo: { formName, ...details } })
    }
  }

  return { sendFormEvent, handleFormError }
}
