import React from 'react'

import { NumericInput as CLNumericInput } from '@guiker/components-library'
import { FieldPath, useFormContext } from '@guiker/react-hook-form'

import { RHFNumericInput, RHFNumericInputProps } from './RHFNumericInput'

export type NumericInputProps<T extends object, P extends FieldPath<T>> = RHFNumericInputProps<T, P>

const NumericInput = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue: defaultValueProp,
  readOnly: readOnlyProps,
  ...props
}: NumericInputProps<T, P>) => {
  const { readOnly: readOnlyForm, watch } = useFormContext()

  if (readOnlyForm) {
    const defaultValue = defaultValueProp || watch(props.name)
    return <CLNumericInput defaultValue={defaultValue} {...props} />
  } else {
    return <RHFNumericInput defaultValue={defaultValueProp} readOnly={readOnlyProps} {...props} />
  }
}

export { NumericInput }
