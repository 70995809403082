import React, { useState } from 'react'

import { Slider, SliderProps as CLSliderProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, ErrorMessages, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type SliderProps<T extends object, P extends FieldPath<T>> = Omit<CLSliderProps, 'name' | 'defaultValue'> &
  ErrorMessages & {
    name: P
    defaultValue?: PathValue<T, P>
    dirtyFormOnlyOnClickRelease?: boolean
  }

export const RHFSlider = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  name,
  dirtyFormOnlyOnClickRelease = false,
  ...props
}: SliderProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorMessage, watch, required } = useFormInput<T, P>({ name, t })

  const [_value, setValue] = useState<number | number[]>(watch(name))

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange: renderPropOnChange, value, ...renderProps }, fieldState }) => {
        return (
          <Slider
            name={name}
            value={_value as any}
            onChange={
              ((_: React.ChangeEvent<{}>, value: number | number[]) => {
                setValue(value)
                !dirtyFormOnlyOnClickRelease && renderPropOnChange(value)
              }) as any
            }
            onChangeCommitted={
              ((_: React.ChangeEvent<{}>, value: number | number[]) => {
                setValue(value)
                dirtyFormOnlyOnClickRelease && renderPropOnChange(value)
              }) as any
            }
            error={!!fieldState.error}
            errorMessage={fieldState.error && errorMessage}
            required={required}
            {...renderProps}
            {...props}
          />
        )
      }}
    />
  )
}
