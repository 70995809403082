import { useMemo } from 'react'

import { useWatch } from 'react-hook-form'

import { get, yup } from '@guiker/shared-framework'

import { Control, FieldError, FieldPath, useFormContext, UseFormReturn as RHFUseFormMethods, UseFormSetValue } from '.'

type UseFormMethods<T, C> = Partial<RHFUseFormMethods<T, C>>

type ErrorTypes = FieldError['type'] | 'matches'

export type ErrorMessages = {
  errorMessages?: {
    [key in ErrorTypes]?: string
  }
}

type FormInput<T extends object, P extends FieldPath<T>, C = unknown> = {
  control: Control<T, C>
  name: P
  inputRef: any
  error: boolean
  errorMessage: string
  errorType: FieldError['type']
  required: boolean
  setValue: UseFormSetValue<T>
  watch: RHFUseFormMethods<T>['watch']
}

type UseFormInput<T extends object, P extends FieldPath<T>, C = unknown> = {
  name: P
  t: (key: string) => string
  namespace?: string
  methods?: UseFormMethods<T, C>
}

export const extractFieldError = (errors: object, name: string): FieldError => get(errors, name)

const useIsRequired = <T>({ name, schema }: { name: string; schema?: yup.Schema<T> }) => {
  const formData = useWatch()

  if (!schema) return false

  const resolved = useMemo(() => {
    return (schema as any)?.resolve({
      value: formData,
      context: formData,
    }) as yup.Schema<unknown>
  }, [schema, formData])

  // formData here is passed as context
  return useMemo(() => {
    try {
      const reached = yup.reach(resolved, name, formData) as unknown as yup.Schema<unknown> & {
        _exclusive: {
          required: boolean
        }
      }
      return reached?._exclusive?.required
    } catch (e) {
      if (e instanceof Error && e.message.includes('cannot resolve an array item')) {
        return false
      }

      if (process.env.REACT_APP_STAGE === 'production') {
        throw e
      } else {
        console.warn('form-input error', e)
      }
    }
  }, [name, resolved])
}

const useGetFormInput = <T extends object, P extends FieldPath<T>, C = unknown>(
  name: P,
  methods: UseFormMethods<T, C> & { schema?: yup.Schema<unknown> },
  t: (key: string) => string,
  namespace = 'errors',
): FormInput<T, P, C> => {
  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    schema,
  } = methods
  const error = extractFieldError(errors, name)
  const required = useIsRequired({ name, schema })

  let errorType = error?.type

  switch (error?.type) {
    case 'typeError':
    case 'oneOf':
      errorType = 'required'
  }

  return {
    control,
    name,
    inputRef: register,
    required,
    errorType,
    error: !!error,
    errorMessage: error && t(`${namespace}:${errorType}`),
    setValue,
    watch,
  }
}

export const useFormInput = <T extends object, P extends FieldPath<T>, C = unknown>({
  name,
  t,
  namespace = 'errors',
  methods,
}: UseFormInput<T, P, C>) => {
  const allMethods = methods || useFormContext()

  return useGetFormInput(name, allMethods, t, namespace)
}
