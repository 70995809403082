import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'
import { isNumber } from '@guiker/lodash'

import { P } from '../../Data Display'
import { Flex } from '../../Layout'
import { Input } from '../Input'
import { NumberFormat } from '../NumberFormat'
import { SecondaryButton } from '../SecondaryButton'

export type NumericInputProps = {
  adornment?: React.ReactNode
  canDirectInput?: boolean
  className?: string
  description?: string
  defaultValue?: number
  value?: number
  disabled?: boolean
  error?: boolean
  errorMessage?: string
  helperText?: string
  id?: string
  label?: string
  labelAlignment?: 'top' | 'left'
  max?: number
  maxWidth?: string | number
  min?: number
  name?: string
  onChange?: (value: number) => void
  readOnly?: boolean
  required?: boolean
  size?: 'small' | 'medium'
  width?: number | string
}

const useStyles = makeStyles(
  (theme) => ({
    numberContainer: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    button: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    buttonSmall: {
      minWidth: theme.spacing(4),
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    numberDisplay: {
      fontVariantNumeric: 'tabular-nums',
      color: ({ disabled }: { disabled: boolean }) =>
        disabled ? theme.palette.grey[30] : theme.palette.text.primary.main,
    },
    numberInputDisplay: {
      width: 'fit-content',
      '& input': {
        maxHeight: 36,
        maxWidth: ({ charLength }: { charLength: number }) => charLength * 10 + theme.spacing(1) * 2 - 2,
        textAlign: 'center',
        padding: `calc(${theme.spacing(1)}px - 2px)`,
      },
    },
    numberInputDisplaySmall: {
      '& input': {
        padding: `calc(${theme.spacing(1) / 2}px - 2px)`,
      },
    },
    active: {
      color: theme.palette.text.primary.main,
      '&:hover': {
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '&:active': {
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
    disabled: {
      color: `${theme.palette.grey[30]} !important`,
      background: `${theme.palette.common.white} !important`,
    },
    readOnly: {
      color: `${theme.palette.grey[10]} !important`,
      borderColor: `${theme.palette.grey[10]} !important`,
      background: `${theme.palette.common.white} !important`,
    },
  }),
  { name: 'NumericInput' },
)

const toInteger = (value?: number | string) => {
  return isNumber(value) && !isNaN(value) ? Math.round(Number(value)) : undefined
}

const NumericInput: React.FC<NumericInputProps> = ({
  className,
  defaultValue,
  disabled,
  max = 10,
  min = 0,
  onChange,
  size = 'medium',
  canDirectInput = false,
  readOnly,
  ...props
}) => {
  const [value, setValue] = useState<number>(defaultValue || min)

  const classes = useStyles({ min, max, value, disabled, charLength: `${max}`.length })

  const decrementIsDisabled = disabled || value <= min
  const incrementIsDisabled = disabled || value >= max

  const forceBoundary = (inputValue: string | number) => {
    const value = Number(inputValue)
    return value < min ? min : value > max ? max : value
  }

  const castAndSetValue = (inputValue: number) => {
    if (isNaN(inputValue)) {
      onChange && onChange(value)
      return
    }
    const newValue = toInteger(inputValue)
    onChange && onChange(newValue)
    setValue(newValue)
  }

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let input = Number(event.target.value)
    if (typeof event.target.value === 'string') {
      input = Number(event.target.value.replaceAll(',', ''))
    }
    const inputValue = toInteger(input)
    const value = inputValue !== undefined ? forceBoundary(inputValue) : undefined
    onChange && onChange(value)
    setValue(value)
  }

  const increment = () => {
    if (!incrementIsDisabled) {
      castAndSetValue(value + 1)
    }
  }

  const decrement = () => {
    if (!decrementIsDisabled) {
      castAndSetValue(value - 1)
    }
  }
  return (
    <Input className={className} {...props}>
      <Flex gap={size === 'small' ? 1 : 2} flexWrap='nowrap' alignItems='center'>
        <SecondaryButton
          size='small'
          className={clsx(classes.button, {
            [classes.buttonSmall]: size === 'small',
            [classes.readOnly]: readOnly,
          })}
          disabled={disabled || decrementIsDisabled || readOnly}
          onClick={decrement}
        >
          -
        </SecondaryButton>
        {canDirectInput ? (
          <NumberFormat
            value={value}
            className={clsx(classes.numberDisplay, classes.numberInputDisplay, {
              [classes.numberInputDisplaySmall]: size === 'small',
            })}
            onChange={castAndSetValue}
            onBlur={onBlur}
            readOnly={readOnly}
            isAllowed={({ floatValue }) => floatValue === undefined || !!floatValue}
            formatter={{
              toDisplay: (value) => toInteger(value),
              toActual: (value) => toInteger(value),
            }}
          />
        ) : (
          <P mb={0} className={classes.numberDisplay}>
            {value}
          </P>
        )}
        <SecondaryButton
          size='small'
          className={clsx(classes.button, {
            [classes.buttonSmall]: size === 'small',
            [classes.readOnly]: readOnly,
          })}
          disabled={disabled || incrementIsDisabled || readOnly}
          onClick={increment}
        >
          +
        </SecondaryButton>
      </Flex>
    </Input>
  )
}

export { NumericInput }
