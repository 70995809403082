import React from 'react'

import { Dropdown as CLDropdown } from '@guiker/components-library'
import { FieldPath, useFormContext } from '@guiker/react-hook-form'

import { DropdownProps as RHFDropdownProps, RHFDropdown } from './RHFDropdown'

export type DropdownProps<T extends object, P extends FieldPath<T>> = RHFDropdownProps<T, P>

const Dropdown = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue: defaultValueProp,
  readOnly: readOnlyProps,
  ...props
}: DropdownProps<T, P>) => {
  const { readOnly: readOnlyForm, getValues } = useFormContext()

  if (readOnlyForm) {
    const defaultValue = defaultValueProp || getValues(props.name)

    return <CLDropdown value={defaultValue} {...props} />
  } else {
    return <RHFDropdown defaultValue={defaultValueProp} readOnly={readOnlyProps} {...props} />
  }
}

export { Dropdown }
