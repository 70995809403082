import React from 'react'

import { Step, StepProps } from '@guiker/components-library'
import { yupResolver } from '@guiker/react-hook-form'
import { yup } from '@guiker/shared-framework'

import { ApiForm, ApiFormProps } from './ApiForm'
import { StepWithSchema, useApiFormStepContext } from './ApiFormStepContext'

type Props<TFormValues, TResult, TContext> = React.PropsWithChildren<
  {
    schema?: yup.Schema<TFormValues>
    apiFormProps: Omit<ApiFormProps<TFormValues, TResult, TContext>, 'formName'> & { formName?: string }
    skipSetData?: boolean
    isContentDrawerOpened?: boolean
  } & StepProps
>

const ApiFormStep = <
  TFormValues,
  TResult,
  TContext extends object = {},
  Steps extends StepWithSchema<TResult>[] | readonly StepWithSchema<TResult>[] = StepWithSchema<TResult>[],
>({
  apiFormProps,
  skipSetData,
  schema,
  isLoading: propsIsLoading,
  ...stepperProps
}: Props<TFormValues, TResult, TContext>) => {
  const { data, setData, formName, readOnly, stepState } = useApiFormStepContext<TResult, Steps>()

  return (
    <ApiForm<TResult, TContext, TFormValues>
      {...apiFormProps}
      readOnly={readOnly}
      formName={apiFormProps.formName ?? `${formName}::${stepperProps.name ?? 'step'}`}
      formOptions={{
        ...apiFormProps.formOptions,
        schema,
        resolver: apiFormProps?.formOptions?.resolver
          ? apiFormProps?.formOptions?.resolver
          : schema
          ? yupResolver(schema, { stripUnknown: true, context: data as unknown as TContext })
          : undefined,
        defaultValues: apiFormProps.formOptions?.defaultValues || (data as any),
      }}
      apiOptions={{
        ...apiFormProps.apiOptions,
        onSuccess: (...onSuccessArgs) => {
          apiFormProps.apiOptions?.onSuccess?.(...onSuccessArgs)
          !skipSetData && setData(onSuccessArgs[0])
          stepperProps?.onClickNext?.(onSuccessArgs[0])
        },
        onSubmitWithoutChange: (...onSubmitWithoutChangeArgs) => {
          apiFormProps.apiOptions?.onSubmitWithoutChange?.(...onSubmitWithoutChangeArgs)
          stepperProps?.onClickNext?.()
        },
      }}
    >
      {({ isLoading, formState: { errors } }) => (
        <Step
          isSubmitting={stepState.isSubmitting || propsIsLoading || isLoading}
          errors={errors}
          readOnly={readOnly}
          {...stepperProps}
        />
      )}
    </ApiForm>
  )
}

export { ApiFormStep }
