import React from 'react'

import { TextField as CLTextField } from '@guiker/components-library'
import { FieldPath, PathValue, useFormContext } from '@guiker/react-hook-form'

import { HiddenInput } from '../HiddenInput'
import { RHFTextField, TextFieldProps as RHFTextFieldProps } from './RHFTextField'

export type TextFieldProps<T extends object = any, P extends FieldPath<T> = any> = RHFTextFieldProps<T, P> & {
  formatValue?: (value: string) => string
}

const TextField = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue: defaultValueProp,
  readOnly: readOnlyProps,
  ...props
}: TextFieldProps<T, P>) => {
  const { readOnly: readOnlyForm, watch } = useFormContext()

  if (readOnlyForm) {
    const defaultValue = defaultValueProp || watch(props.name)

    return <CLTextField value={defaultValue || '-'} readOnly={readOnlyForm || readOnlyProps} {...props} />
  } else {
    if (props.formatValue && readOnlyProps) {
      const defaultValue = defaultValueProp || watch(props.name)
      const { label, ...otherProps } = props

      return (
        <>
          <HiddenInput {...otherProps} />
          <CLTextField label={label} value={defaultValue && props.formatValue(defaultValue)} readOnly={true} />
        </>
      )
    }

    return (
      <RHFTextField
        defaultValue={defaultValueProp || ((readOnlyProps && '-') as PathValue<T, P>)}
        readOnly={readOnlyProps}
        {...props}
      />
    )
  }
}

export { TextField }
