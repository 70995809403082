import React from 'react'

import { useT } from '../../../hooks/i18n'
import { PageLayout, PageLayoutProps } from '../../Layout'
import { ScrollToTop } from '../../Navigation'
import { ShiftedDrawerContent } from '../Drawer'
import { ButtonContainer, ButtonContainerProps } from './ButtonContainer'

export type StepProps = React.PropsWithChildren &
  Omit<PageLayoutProps, 'forceTakeover'> &
  Omit<ButtonContainerProps, 'title' | 'onClickNext'> & {
    onClickNext?: (args?: { asLastStep?: boolean }) => void
    hasDrawerNav?: boolean
    isDrawerMinimized?: boolean
    isNested?: boolean
    isContentDrawerOpened?: boolean
    skip?: boolean
    readOnly?: boolean
  }

const Step: React.FC<StepProps> = ({
  children,
  hasBackButton,
  hasDrawerNav = false,
  hasButtonContainer = true,
  isDrawerMinimized,
  hasNextButton,
  backLabel,
  onClickBack,
  nextDisabled,
  nextLabel,
  nextButtonType = 'submit',
  onClickNext,
  isSubmitting,
  errors,
  isNested = false,
  progression,
  isContentDrawerOpened,
  readOnly,
  ...pageLayoutProps
}) => {
  const { tBase } = useT()
  const nestedProps = isNested
    ? {
        maxWidth: 1440,
        pageLayoutProps: { noPadding: true, noBreadcrumpSpacer: true },
      }
    : {}

  return (
    <PageLayout
      maxWidth={nestedProps.maxWidth ?? 900}
      hasButtonContainer={true}
      {...pageLayoutProps}
      {...nestedProps.pageLayoutProps}
    >
      <ScrollToTop />
      <ShiftedDrawerContent isDrawerOpen={isContentDrawerOpened}>{children}</ShiftedDrawerContent>
      {hasButtonContainer && (
        <ButtonContainer
          maxWidth={nestedProps.maxWidth ?? 900}
          hasBackButton={hasBackButton}
          hasNextButton={hasNextButton}
          backLabel={backLabel || tBase('back')}
          onClickBack={onClickBack}
          onClickNext={readOnly || nextButtonType !== 'submit' ? onClickNext : undefined}
          nextDisabled={nextDisabled}
          nextLabel={nextLabel || tBase('next')}
          nextButtonType={nextButtonType}
          isSubmitting={isSubmitting}
          errors={errors}
          hasDrawerNav={hasDrawerNav}
          isDrawerMinimized={isDrawerMinimized}
          progression={progression}
        />
      )}
    </PageLayout>
  )
}

export { Step }
