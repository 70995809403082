import { TOptions, useTranslation } from '@guiker/i18n'
import { DeepPartial, get, GetByDotNotation, Path, yup } from '@guiker/shared-framework'

type Dependency = {
  [key in string]: string | number | boolean
}

export type InputKeyProps = {
  i18nKey?: string
  namespace?: string
  dependency?: Dependency
}

export type GetInputProps<T> = <D extends DeepPartial<T>>(
  key: string,
  inputKeyProps?: InputKeyProps,
) => {
  label: string
  name: string
  readOnly: boolean
  required: boolean
  defaultValue: D
}

export type DefaultValue<T> = string | number | readonly string[] | object | T

export const useGetInputProps = <T extends object>({
  namespaces,
  formPrefix,
  tPrefix = formPrefix,
  readOnly = false,
  defaultValue,
  schema,
  schemaPrefix,
  style,
}: {
  defaultValue?: T
  tPrefix?: string
  formPrefix?: string
  readOnly?: boolean
  namespaces?: Parameters<typeof useTranslation>[0]
  schema?: yup.ObjectSchema
  schemaPrefix?: string
  style?: object
}): GetInputProps<DefaultValue<T>> => {
  const { t } = useTranslation(namespaces)

  //https://github.com/jquense/yup/issues/1280 for reference to validate final schema

  return (key: string, { i18nKey = key, namespace = '', dependency = {} }: InputKeyProps = {}) => {
    const name = `${formPrefix ? `${formPrefix}.` : ''}${key}`
    let reached = schema
      ? (yup.reach(schema, schemaPrefix ? `${schemaPrefix}.${key}` : schemaPrefix === '' ? `${key}` : name) as any)
      : undefined

    if (reached?._deps?.length && dependency) {
      reached = reached.resolve({
        parent: reached._deps.reduce(
          (result: Dependency, dep: string) => ({
            ...result,
            ...(dependency[dep] ? { [dep]: dependency[dep] } : {}),
          }),
          {},
        ),
      })
    }
    const translationKey = [namespace || tPrefix || undefined, i18nKey].filter((v) => !!v).join('.')
    return {
      label: t(translationKey),
      name,
      readOnly: readOnly,
      defaultValue: defaultValue && get(defaultValue, name, undefined),
      required: !!reached?._exclusive?.required,
      ...style,
    }
  }
}

export const useGetInputPropsWithUseT =
  <T = never>() =>
  <FormPrefix extends [T] extends [never] ? string : Path<T> = [T] extends [never] ? string : Path<T>>({
    t,
    formPrefix,
  }: {
    t: (key: string, options?: TOptions) => string
    formPrefix?: FormPrefix
  }) => {
    return <Name extends Path<GetByDotNotation<T, FormPrefix>>>(
      name: Name,
      { i18nKey = name as string, options }: { options?: TOptions; i18nKey?: string } = { i18nKey: name },
    ) => {
      type FullFieldName = [FormPrefix] extends [never] ? Name : `${FormPrefix}.${Name}`
      const fullFieldName = (formPrefix ? `${formPrefix}.${name}` : name) as FullFieldName

      return {
        label: t(i18nKey as string, options),
        name: fullFieldName,
      }
    }
  }
