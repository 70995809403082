import React from 'react'

import { Modal } from '../../Feedback/Modal'
import { DisplayBody } from '../Typography'
import { useNoteContext } from './NoteContext'

export const NoteModal = () => {
  const {
    noteContent: { content, title },
    handleNote,
    isNoteOpen,
  } = useNoteContext()
  return (
    <Modal open={isNoteOpen} onClose={() => handleNote()} title={title}>
      {typeof content === 'string' ? <DisplayBody>{content}</DisplayBody> : <div>{content}</div>}
    </Modal>
  )
}
