import { en as authentication } from './authentication'
import { en as commonBackgroundCheck } from './common/background-check'
import { en as common } from './common/base'
import { en as commonBaseListing } from './common/base-listing'
import { en as commonBooking } from './common/booking'
import { en as commonComponents } from './common/components'
import { en as commonInvestmentAssumption } from './common/investment-assumption'
import { en as commonPayment } from './common/payment'
import { en as commonPayout } from './common/payout'
import { en as commonPropRental } from './common/prop-rental'
import { en as commonPropertySale } from './common/property-sale'
import { en as commonPropSharing } from './common/propsharing'
import { en as commonRealEstate } from './common/real-estate'
import { en as commonRentPayment } from './common/rent-payment'
import { en as commonRentalListing } from './common/rental-listing'
import { en as commonTask } from './common/task'
import { en as commonTenantPreference } from './common/tenant-preference'
import { en as errors } from './errors'
import { en as listing } from './listing'
import { en as mainAccount } from './main/account'
import { en as mainBackgroundCheck } from './main/background-check'
import { en as mainBlog } from './main/blog'
import { en as mainBooking } from './main/booking'
import { en as mainBookingApplicant } from './main/booking-applicant'
import { en as mainBookingDocument } from './main/booking-document'
import { en as mainBookingRentPayment } from './main/booking-rent-payment'
import { en as mainInvestments } from './main/investments'
import { en as mainMyInvestments } from './main/my-investments'
import { en as mainNotification } from './main/notification'
import { en as mainPayment } from './main/payment'
import { en as mainPayout } from './main/payout'
import { en as mainPropertySale } from './main/property-sale'
import { en as mainPropSharing } from './main/propsharing'
import { en as mainRentalListing } from './main/rental-listing'
import { en as mainRentalListingInquiry } from './main/rental-listing-inquiry'
import { en as mainRentalTenancy } from './main/rental-tenancy'
import { en as mainStatistic } from './main/statistic'
import { en as mainTenantApplication } from './main/tenant-application'
import { en as mainTenantPreference } from './main/tenant-preference'
import { en as mainWebapp } from './main/webapp'
import { en as officeBooking } from './office/booking'
import { en as officeLeaseContract } from './office/lease-contract'
import { en as officeNote } from './office/note'
import { en as officePayment } from './office/payment'
import { en as officePayout } from './office/payout'
import { en as officePropRental } from './office/prop-rental'
import { en as officePropertySale } from './office/property-sale'
import { en as officePropSharing } from './office/propsharing'
import { en as officeRealEstate } from './office/real-estate'
import { en as officeRentPayment } from './office/rent-payment'
import { en as officeRentalListing } from './office/rental-listing'
import { en as officeRentalListingCreation } from './office/rental-listing-creation'
import { en as officeRentalListingInquiry } from './office/rental-listing-inquiry'
import { en as officeRentalListingLeadListing } from './office/rental-listing-lead-listing'
import { en as officeSearch } from './office/search'
import { en as officeUser } from './office/user'
import { en as officeWebapp } from './office/webapp'
import { en as payout } from './payout'

export const en = {
  authentication,
  errors,
  common,
  listing,
  payout,
  'common-backgroundCheck': commonBackgroundCheck,
  'common-baseListing': commonBaseListing,
  'common-booking': commonBooking,
  'common-components': commonComponents,
  'common-investmentAssumption': commonInvestmentAssumption,
  'common-propertySale': commonPropertySale,
  'common-propsharing': commonPropSharing,
  'common-payment': commonPayment,
  'common-payout': commonPayout,
  'common-propRental': commonPropRental,
  'common-realEstate': commonRealEstate,
  'common-rentalListing': commonRentalListing,
  'common-rentPayment': commonRentPayment,
  'common-task': commonTask,
  'common-tenantPreference': commonTenantPreference,
  'main-account': mainAccount,
  'main-backgroundCheck': mainBackgroundCheck,
  'main-blog': mainBlog,
  'main-booking': mainBooking,
  'main-bookingApplicant': mainBookingApplicant,
  'main-bookingDocument': mainBookingDocument,
  'main-bookingRentPayment': mainBookingRentPayment,
  'main-propsharing': mainPropSharing,
  'main-investments': mainInvestments,
  'main-myInvestments': mainMyInvestments,
  'main-notification': mainNotification,
  'main-payment': mainPayment,
  'main-payout': mainPayout,
  'main-propertySale': mainPropertySale,
  'main-rentalListing': mainRentalListing,
  'main-rentalListingInquiry': mainRentalListingInquiry,
  'main-rentalTenancy': mainRentalTenancy,
  'main-statistic': mainStatistic,
  'main-tenantApplication': mainTenantApplication,
  'main-tenantPreference': mainTenantPreference,
  webapp: mainWebapp,
  'main-webapp': mainWebapp,
  'office-booking': officeBooking,
  'office-propRental': officePropRental,
  'office-propsharing': officePropSharing,
  'office-leaseContract': officeLeaseContract,
  'office-propertySale': officePropertySale,
  'office-rentalListing': officeRentalListing,
  'office-rentalListingCreation': officeRentalListingCreation,
  'office-rentalListingInquiry': officeRentalListingInquiry,
  'office-rentalListingLeadListing': officeRentalListingLeadListing,
  'office-note': officeNote,
  'office-payout': officePayout,
  'office-payment': officePayment,
  'office-realEstate': officeRealEstate,
  'office-rentPayment': officeRentPayment,
  'office-search': officeSearch,
  'office-user': officeUser,
  'office-webapp': officeWebapp,
}
