import React from 'react'

import { NumericInput, NumericInputProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, ErrorMessages, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type RHFNumericInputProps<T extends object, P extends FieldPath<T>> = Omit<
  NumericInputProps,
  'name' | 'defaultValue'
> &
  ErrorMessages & {
    name: P
    defaultValue?: PathValue<T, P>
  }

const RHFNumericInput = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  name,
  min,
  max,
  onChange,
  required: propsRequired,
  ...props
}: RHFNumericInputProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorMessage, required } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, onChange: renderPropOnChange, value, ...renderProps }, fieldState }) => (
        <>
          <input
            type='hidden'
            name={name}
            min={min}
            max={max}
            value={value}
            // needs both formDefaultValue for future form changes and defaultValue for initial form state
            ref={ref}
          />
          <NumericInput
            min={min}
            max={max}
            onChange={(value) => {
              !!onChange && onChange(value)
              renderPropOnChange(value)
            }}
            defaultValue={value}
            required={propsRequired ?? required}
            error={!!fieldState.error}
            errorMessage={fieldState.error && errorMessage}
            {...props}
            {...renderProps}
          />
        </>
      )}
    />
  )
}

export { RHFNumericInput }
