import React, { PropsWithChildren, useState } from 'react'

import { compact } from '@guiker/shared-framework'

import { Grid, TwoColumnsGridLayout } from '../../Layout'
import { Input, InputProps } from '../Input'
import { extractInputProps } from '../Input/extract-input-props'
import { Checkbox, CheckboxProps } from './Checkbox'

type DisplayDirection = 'row' | 'column'

type OptionsProps = CheckboxProps

type CheckboxGroupProps = InputProps & {
  direction?: DisplayDirection
  disabled?: boolean
  error?: boolean
  errorMessage?: string
  helperText?: string
  label?: string
  maxWidth?: string | number
  name?: string
  options: OptionsProps[]
  readOnly?: boolean
  onChange?: (values: string[]) => void
  value?: string[]
  LayoutComponent?: React.FC<PropsWithChildren>
  inputRef?: React.Ref<HTMLInputElement>
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const { inputProps, otherProps } = extractInputProps(props)
  const { disabled, name, readOnly, value } = inputProps
  const { LayoutComponent = TwoColumnsGridLayout, options, onChange } = otherProps

  const [values, setValues] = useState((value as string[]) || [])

  const updateValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues(compact(values.filter((v) => v !== e.target.value).concat(e.target.checked ? e.target.value : null)))

    onChange?.(values)
  }

  return (
    <Input {...inputProps}>
      <LayoutComponent>
        {options
          .filter((o) => !!o)
          .map(({ label, ...checkboxProps }, index) => {
            return (
              <Grid item key={`checkbox-group-${index}`}>
                <Checkbox
                  disabled={disabled || checkboxProps.disabled}
                  readOnly={readOnly || checkboxProps.readOnly}
                  label={label as string}
                  name={`${name}.${index}`}
                  onChange={(e) => updateValues(e)}
                  defaultChecked={values.includes(checkboxProps.value as string)}
                  {...checkboxProps}
                />
              </Grid>
            )
          })}
      </LayoutComponent>
    </Input>
  )
}

export { CheckboxGroup, CheckboxGroupProps }
