import React from 'react'

import { DatePicker as CLDatePicker } from '@guiker/components-library'
import { FieldPath, useFormContext } from '@guiker/react-hook-form'

import { RHFDatePicker, RHFDatePickerProps } from './RHFDatePicker'

export type DatePickerProps<T extends object, P extends FieldPath<T>> = RHFDatePickerProps<T, P>

const DatePicker = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue: defaultValueProp,
  readOnly: readOnlyProps,
  ...props
}: DatePickerProps<T, P>) => {
  const { readOnly: readOnlyForm, watch } = useFormContext()

  if (readOnlyForm) {
    const defaultValue = defaultValueProp || watch(props.name)

    return <CLDatePicker {...props} defaultValue={defaultValue} />
  } else {
    return <RHFDatePicker defaultValue={defaultValueProp} readOnly={readOnlyProps} {...props} />
  }
}

export { DatePicker }
