import React from 'react'

import { FlexAlign, makeStyles, theme } from '@guiker/components-core'
import { isNumber, isString } from '@guiker/lodash'

import { useMediaQuery } from '../../../hooks'
import { flattenChildren } from '../../../utils'
import { Divider, P, TypographyProps } from '../../Data Display'
import { Box, Flex } from '../../Layout'

const useStyles = makeStyles(
  {
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    topActions: {
      marginTop: 0,
    },
  },
  { name: 'PageSection' },
)

export type PageSectionProps = React.PropsWithChildren & {
  hasDivider?: boolean
  headerAlignItems?: FlexAlign
  spacing: number
  subtitle?: string | React.ReactNode
  title?: string | React.ReactNode
  TitleComponent: React.FC<React.PropsWithChildren & { mb: TypographyProps['mb'] }>
  titleMb: number
  titleSubtitleGap?: TypographyProps['mb']
  topActions?: React.ReactNode
}

export const PageSection: React.FC<PageSectionProps> = ({
  children,
  hasDivider,
  headerAlignItems,
  subtitle,
  title,
  titleMb,
  titleSubtitleGap = 2,
  TitleComponent,
  spacing,
  topActions,
}) => {
  const classes = useStyles({
    hasSubtitle: !!subtitle,
  })
  const childrenArr = flattenChildren(children)
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Flex flexDirection='column' fullWidth>
      {(title || subtitle || topActions) && (
        <Box mb={!!title ? titleMb : 2}>
          {(title || topActions) && (
            <Flex
              justifyContent='space-between'
              flexWrap='wrap'
              alignItems={headerAlignItems || (isMobile ? 'flex-start' : 'center')}
              mb={subtitle ? titleSubtitleGap : 0}
              gap={2}
            >
              {title && (isString(title) || isNumber(title)) ? (
                <TitleComponent mb={0}>{title}</TitleComponent>
              ) : title ? (
                title
              ) : (
                <div />
              )}
              {topActions && (
                <Flex gap={2} className={classes.topActions} alignSelf={isMobile ? 'flex-start' : 'center'}>
                  {topActions}
                </Flex>
              )}
            </Flex>
          )}
          {subtitle && <P color={60}>{subtitle}</P>}
        </Box>
      )}
      <Flex flexDirection='column' gap={hasDivider ? spacing / 2 + 2 : spacing}>
        {childrenArr.map((child, index) => {
          return (
            <React.Fragment key={index}>
              {child}
              {hasDivider && index !== childrenArr.length - 1 && <Divider width='100%' />}
            </React.Fragment>
          )
        })}
      </Flex>
    </Flex>
  )
}
