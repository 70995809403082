import { Control, FieldPath, FieldPathValue, FieldValues, useWatch as baseUseWatch } from 'react-hook-form'

import { FormContext, useFormContext as baseUseFormContext } from './FormProvider'
import { useReadOnlyFormContext } from './ReadOnlyForm'

export const useFormContext = <TFieldValues extends FieldValues>(): FormContext<TFieldValues> => {
  const readOnlyContext = useReadOnlyFormContext<TFieldValues>()

  if (readOnlyContext) return { ...readOnlyContext, readOnly: true }

  const baseFormContext = baseUseFormContext<TFieldValues>()

  if (!baseFormContext) return

  return {
    ...baseFormContext,
    readOnly: false,
  }
}

export const useWatch = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TDefaultValue extends FieldPathValue<TFieldValues, TFieldName> = FieldPathValue<TFieldValues, TFieldName>,
>(props?: {
  defaultValue?: TDefaultValue
  disabled?: boolean
  name?: TFieldName
  control?: Control<TFieldValues>
  exact?: boolean
}) => {
  const readOnlyContext = useReadOnlyFormContext<TFieldValues>()

  if (readOnlyContext)
    return readOnlyContext.watch(props?.name as TFieldName) as FieldPathValue<TFieldValues, TFieldName>

  return baseUseWatch<TFieldValues, TFieldName>(props as any) as TDefaultValue
}
