import React from 'react'

import { AlertIcon } from '@guiker/icons'

import { Box, BoxProps, Flex } from '../../Layout'
import { FormHelperText } from '../FormHelperText/FormHelperText'
import { InputLabel, InputLabelProps } from '../InputLabel'
import { extractInputProps } from './extract-input-props'

export type InputProps = React.PropsWithChildren &
  Omit<BoxProps, 'onChange' | 'onBlur' | 'onFocus'> & {
    defaultChecked?: boolean
    defaultValue?: string | number | readonly string[]
    checked?: boolean
    className?: string
    condensed?: boolean
    disabled?: boolean
    error?: boolean
    errorMessage?: string
    helperText?: string | boolean | React.ReactNode
    id?: string
    name?: string
    label?: string | React.ReactNode | number | any
    labelClassName?: string
    readOnly?: boolean
    required?: boolean
    value?: string | number | readonly string[]
    labelAdornment?: InputLabelProps['adornment']
    labelAlignment?: 'top' | 'left'
    withLabelPlaceholder?: boolean
  }
const Input: React.FC<InputProps> = ({ children, ...props }) => {
  const { inputProps, otherProps } = extractInputProps(props)
  const {
    condensed,
    className,
    disabled,
    errorMessage,
    helperText,
    label,
    name,
    id = name,
    required,
    readOnly,
    labelAdornment,
    withLabelPlaceholder,
  } = inputProps
  const { labelClassName, labelAlignment = 'top', ...boxProps } = otherProps

  const helperTextId = helperText ? `${id}-helper-text` : undefined
  const errorHelperTextId = errorMessage ? `${id}-error-text` : undefined

  return (
    <Flex className={className} flexDirection='column' justifyContent='flex-start' gap={1} {...boxProps}>
      <Flex
        flexDirection={labelAlignment === 'top' ? 'column' : 'row'}
        alignItems={labelAlignment === 'left' ? 'center' : undefined}
        justifyContent={labelAlignment === 'left' ? 'space-between' : undefined}
        gap={1}
      >
        {label != null ? (
          <InputLabel
            condensed={condensed}
            disabled={disabled}
            readOnly={readOnly}
            className={labelClassName}
            htmlFor={id}
            required={required}
            text={label}
            adornment={{ ...labelAdornment, position: 'end' }}
          />
        ) : withLabelPlaceholder ? (
          <Box height={condensed ? 16 : 20} />
        ) : (
          <></>
        )}
        {children}
      </Flex>
      {errorMessage && (
        <FormHelperText
          disabled={disabled}
          color='alert'
          role='alert'
          aria-label={errorHelperTextId}
          id={errorHelperTextId}
        >
          <Flex gap={1} alignItems='center' flexWrap='nowrap'>
            <AlertIcon />
            {errorMessage}
          </Flex>
        </FormHelperText>
      )}
      {helperText && (
        <FormHelperText aria-label='helper-message' id={helperTextId}>
          {helperText}
        </FormHelperText>
      )}
    </Flex>
  )
}
export { Input }
