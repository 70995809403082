import React from 'react'

import { CurrencyTextField, CurrencyTextFieldProps as CLCurrencyTextFieldProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, ErrorMessages, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type CurrencyTextFieldProps<T extends object, P extends FieldPath<T>> = Omit<
  CLCurrencyTextFieldProps,
  'name' | 'defaultValue'
> &
  ErrorMessages & {
    name: P
    defaultValue?: PathValue<T, P>
  }

const RHFCurrencyTextField = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  name,
  onChange,
  errorMessages = {},
  required: propsRequired,
  ...props
}: CurrencyTextFieldProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorType, errorMessage, required } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange: onChangeRenderProps, ref, ...renderProps }, fieldState }) => (
        <CurrencyTextField
          onChange={(event) => {
            onChange && onChange(event)
            onChangeRenderProps(event)
          }}
          name={name}
          error={!!fieldState.error}
          errorMessage={errorMessages[errorType] || (fieldState.error && errorMessage)}
          inputRef={ref}
          defaultValue={defaultValue}
          required={propsRequired ?? required}
          {...renderProps}
          {...props}
        />
      )}
    />
  )
}

export { RHFCurrencyTextField }
