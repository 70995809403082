import React, { useCallback } from 'react'

import {
  Button,
  ButtonProps,
  ButtonWithLoader,
  ButtonWithLoaderProps,
  SecondaryButton,
} from '@guiker/components-library'
import { selectFromMap, sleep } from '@guiker/lodash'
import { useFormContext } from '@guiker/react-hook-form'
import { MutationFunction, useMutation } from '@guiker/react-query'

import { MutationOptions, OnSubmit } from '../../types'
import { useApiFormContext } from './ApiFormContext'

const ButtonMap = {
  primary: Button,
  secondary: SecondaryButton,
  default: Button,
}

const selectButton = selectFromMap<React.FC<ButtonProps & { isLoading: boolean }>>(ButtonMap)

type ButtonType = 'primary' | 'secondary'

type UseApiFormAction<TFormValues, TResult> = {
  onClick?: OnSubmit<TResult, TFormValues>
  options?: MutationOptions<TResult, TFormValues> & { skipValidation?: boolean }
}

type ApiFormActionProps<TFormValues, TResult> = UseApiFormAction<TFormValues, TResult> & {
  children?: React.ReactNode
  disabled?: boolean
  buttonType?: ButtonType
  buttonProps?: Omit<ButtonWithLoaderProps, 'onClick' | 'type' | 'buttonType'>
  isLoading?: boolean
  fullWidth?: boolean
}

export const useApiFormAction = <TResult, TFormValues extends Record<string, any> = Record<string, any>>(
  args: UseApiFormAction<TFormValues, TResult> = {},
) => {
  const { onClick = () => sleep(100) as Promise<TResult>, options = {} } = args
  const { isSubmitting, generateFormSubmitHandler } = useApiFormContext()
  const { formState, trigger } = useFormContext()
  const { mutate, isLoading: isHandlingClick } = useMutation(onClick as MutationFunction<TResult, TFormValues>, options)

  const handleFormSubmit = useCallback(generateFormSubmitHandler(mutate), [formState.isDirty, isSubmitting, onClick])

  const onSubmit = async (e?: any) => {
    const isValid = await trigger()
    return isValid ? handleFormSubmit(e) : undefined
  }

  return {
    onSubmit,
    errors: formState.errors,
    isLoading: isSubmitting || isHandlingClick,
  }
}

export const ApiFormAction = <TResult, TFormValues extends Record<string, any> = Record<string, any>>({
  onClick,
  options = {},
  children,
  buttonType = 'primary',
  disabled,
  isLoading: isLoadingForced = false,
  fullWidth = false,
  buttonProps,
}: ApiFormActionProps<TFormValues, TResult>) => {
  const { onSubmit, errors, isLoading } = useApiFormAction({ onClick, options })

  return (
    <ButtonWithLoader
      {...buttonProps}
      type='button'
      buttonComponent={selectButton(buttonType)}
      onClick={onSubmit}
      disabled={disabled || isLoadingForced || isLoading}
      isLoading={isLoading}
      fullWidth={fullWidth}
      errors={errors}
    >
      {children}
    </ButtonWithLoader>
  )
}
