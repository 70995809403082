import React from 'react'

import { NumberFormat as CLNumberFormat, numberFormatter } from '@guiker/components-library'
import { FieldPath, useFormContext } from '@guiker/react-hook-form'

import { NumberFormatProps as RHFNumberFormatProps, RHFNumberFormat } from './RHFNumberFormat'

export type NumberFormatProps<T extends object, P extends FieldPath<T>> = RHFNumberFormatProps<T, P>

export const NumberFormat = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue: defaultValueProp,
  readOnly: readOnlyProps,
  ...props
}: NumberFormatProps<T, P>) => {
  const { readOnly: readOnlyForm, watch } = useFormContext()

  if (readOnlyForm) {
    const defaultValue = defaultValueProp || watch(props.name)
    return <CLNumberFormat value={defaultValue ?? '-'} {...props} />
  } else {
    return <RHFNumberFormat defaultValue={defaultValueProp} readOnly={readOnlyProps} {...props} />
  }
}

export const PercentNumberFormat = <T extends object = any, P extends FieldPath<T> = any>({
  decimalPrecision,
  ...props
}: Omit<NumberFormatProps<T, P>, 'formatter' | 'endAdornment'> & { decimalPrecision?: number }) => {
  return (
    <NumberFormat
      endAdornment={'%'}
      textAlign='end'
      decimalScale={2}
      allowNegative={false}
      max={100}
      formatter={numberFormatter.percentage(decimalPrecision ?? 4)}
      {...props}
    />
  )
}
