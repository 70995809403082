import React from 'react'

import { ColumnGridLayout, ColumnGridLayoutProps } from '../ColumnGridLayout'

type FourColumnsGridLayoutProps = Omit<ColumnGridLayoutProps, 'gridTemplateColumns' | 'gridColumn'>

const FourColumnsGridLayout: React.FC<FourColumnsGridLayoutProps> = ({ children, gap, sm, ...props }) => {
  return (
    <ColumnGridLayout
      gap={gap}
      gridColumn={{ start: 'auto', span: 1 }}
      gridTemplateColumns={4}
      sm={{ gridTemplateColumns: 1, gridColumn: { span: 4 }, ...sm }}
      {...props}
    >
      {children}
    </ColumnGridLayout>
  )
}

export { FourColumnsGridLayout }
