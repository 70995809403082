import React from 'react'

import { clsx } from '@guiker/clsx'
import { isAtMostTablette, MinWidth } from '@guiker/components-core'
import { useTranslation } from '@guiker/i18n'

import { isMobile, makeStyles, theme } from '../../../styles'
import { EmptyStateMessage, Flex, PSmall } from '../..'
import { ColumnConfig } from './column-config'
import { Pagination } from './pagination'
import { Sort } from './sort'
import { TableBody } from './TableBody'
import { TableHeader } from './TableHeader'
import { TablePagination } from './TablePagination'

export type TableProps<T> = {
  className?: string
  columns: ColumnConfig<T>[]
  condensed?: boolean
  data: T[]
  emptyState?: {
    label: string
    description?: string
  }
  sort?: Sort
  pagination?: Partial<Pagination>
  showLabel?: boolean
  isRowCollapsible?: boolean | ((data: T) => boolean)
  collapsibleComponent?: React.FC<{ item: T }>
  collapsibleOptions?: {
    isOpen: boolean
  }
  isLoading?: boolean
  onRowClick?: (data: T, event: React.MouseEvent) => unknown
  minWidth?: MinWidth
}

const useStyles = makeStyles(
  {
    root: {
      width: '100%',
      overflow: 'hidden',
      [isAtMostTablette]: {
        overflowX: 'scroll',
      },
    },
    table: ({ minWidth }: { minWidth: MinWidth }) => ({
      width: '100%',
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
      [isAtMostTablette]: {
        minWidth: minWidth || theme.dimensions.pageLayout.maxWidth.desktop,
      },
    }),
    pagination: {
      flexDirection: 'row',
      [isMobile]: {
        flexDirection: 'column',
      },
    },
    paginationInfo: {
      marginBottom: 0,
      [isMobile]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
  { name: 'Table' },
)

export const BaseTable: React.FC<
  React.PropsWithChildren & { className?: string; minWidth?: MinWidth; fullWidth?: boolean }
> = ({ children, className, minWidth, fullWidth }) => {
  const classes = useStyles({ fullWidth, minWidth })

  return (
    <div className={clsx(classes.root, className)}>
      <table className={classes.table}>{children}</table>
    </div>
  )
}

const Table = <T extends object>({
  className,
  columns: columnsProp,
  collapsibleComponent,
  collapsibleOptions,
  data,
  emptyState,
  sort,
  pagination,
  isRowCollapsible,
  isLoading,
  onRowClick,
  showLabel = true,
  condensed = false,
  minWidth,
}: TableProps<T>) => {
  const classes = useStyles({ minWidth })
  const { t } = useTranslation()

  const columns = isRowCollapsible
    ? [
        ...columnsProp,
        {
          name: 'collapseRow',
          size: 0.4,
          sortable: false,
          renderValue: () => <div />,
        },
      ]
    : columnsProp

  if (emptyState && (!data || !data.length)) {
    const { label, description } = emptyState
    return <EmptyStateMessage label={label} description={description} />
  }

  return (
    <>
      <BaseTable className={className} minWidth={minWidth}>
        <TableHeader columns={columns} sort={sort} showLabel={showLabel} />
        <TableBody
          condensed={condensed}
          columns={columns}
          data={data}
          onRowClick={onRowClick}
          isRowCollapsible={isRowCollapsible}
          collapsibleComponent={collapsibleComponent}
          isLoading={isLoading}
          collapsibleOptions={collapsibleOptions}
        />
      </BaseTable>
      {pagination && (
        <Flex justifyContent='space-between' alignItems='center' mt={2} fullWidth className={classes.pagination}>
          <PSmall textAlign='center' className={classes.paginationInfo}>
            {
              t('common-components:pagination', {
                min: (pagination.page - 1) * pagination.perPage + 1,
                max: Math.min(pagination.page * pagination.perPage, pagination.total) || 0,
                total: pagination.total || 0,
              }) as string
            }
          </PSmall>
          <TablePagination {...pagination} />
        </Flex>
      )}
    </>
  )
}

export { Table }
