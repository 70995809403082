import React from 'react'

import { RadioGroup, RadioGroupProps as CLRadioGroupProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, ErrorMessages, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type RadioGroupProps<T extends object, P extends FieldPath<T>> = Omit<
  CLRadioGroupProps,
  'name' | 'defaultValue'
> &
  ErrorMessages & {
    name: P
    defaultValue?: PathValue<T, P>
  }

export const RHFRadioGroup = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  name,
  options,
  onChange,
  required: propsRequired,
  ...props
}: RadioGroupProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorMessage, setValue, required } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      render={({ field: { ref, onChange: propsOnChange, ...renderProps }, fieldState }) => (
        <RadioGroup
          options={options}
          onChange={(event, value) => {
            setValue(name, options.find((o) => o.value?.toString() === value)?.value as PathValue<T, P>)
            onChange?.(event, value)
          }}
          error={!!fieldState.error}
          errorMessage={fieldState.error && errorMessage}
          ref={ref}
          required={propsRequired ?? required}
          {...renderProps}
          {...props}
        />
      )}
    />
  )
}
