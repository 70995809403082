import React, { createContext, useState } from 'react'

import { generateUseContext } from '@guiker/react-context'

import { useModal } from '../../../hooks'
import { NoteModal } from './NoteModal'

type NoteContext = {
  handleNote: ({ title, content }?: { title: string; content: string | React.ReactNode }) => void
  isNoteOpen: boolean
  noteContent: { title: string; content: string | React.ReactNode }
}

export const NoteContext = createContext<NoteContext>(null)

export const NoteContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isOpen: isNoteOpen, openModal: openNote, closeModal: closeNote } = useModal()
  const [noteContent, setNoteContent] = useState({ title: undefined, content: undefined })

  const handleNote = (noteContent?: { title: string; content: string | React.ReactNode }) => {
    setNoteContent(noteContent)
    isNoteOpen ? closeNote() : openNote()
  }

  const value = {
    handleNote,
    isNoteOpen,
    noteContent,
  }

  return (
    <NoteContext.Provider value={value}>
      {children}
      <NoteModal />
    </NoteContext.Provider>
  )
}

export const useNoteContext = generateUseContext(NoteContext, { shouldThrowIfUndefined: false })
