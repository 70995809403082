import React from 'react'

import { TOptions } from 'i18next'
import { Trans, useTranslation } from 'react-i18next'

import { compact } from '@guiker/lodash'

type TransProps<K extends string> = React.PropsWithChildren & {
  i18nKey?: K | K[]
  values?: {}
}

export const buildUseT =
  ({ domain }: { domain: string }) =>
  (args?: Omit<Parameters<typeof useT>[0], 'domain'>) =>
    useT({ ...args, domain })

const safeConcat = (prefix: string, key: string) => {
  if (!key || key === '') return prefix

  if (prefix.endsWith(':') || prefix.endsWith('.') || key.startsWith('.')) {
    return `${prefix}${key}`
  }

  return compact([prefix, key]).join('.')
}

export const useT = ({
  domain,
  entity,
  basePrefix,
  screenName,
  componentName,
}: { domain?: string; screenName?: string; entity?: string; componentName?: string; basePrefix?: string } = {}) => {
  const tErrorNamespace = 'errors'
  const tCommonNamespace = 'common'
  const tSharedNamespace = `common-${domain}`
  const tMainNamespace = `main-${domain}`
  const tOfficeNamespace = `office-${domain}`
  const [_t] = useTranslation([tCommonNamespace, tSharedNamespace, tMainNamespace, tOfficeNamespace])

  const tSharedPrefix = entity ? `${tSharedNamespace}:${entity}` : `${tSharedNamespace}:`
  const tCommonPrefix = basePrefix ? `${tCommonNamespace}:${basePrefix}` : `${tCommonNamespace}:`
  const tComponentPrefix = componentName ? `${tSharedNamespace}:components.${componentName}` : `${tSharedNamespace}:`
  const tOfficePrefix = screenName ? `${tOfficeNamespace}:${screenName}` : `${tOfficeNamespace}:`
  const tMainPrefix = screenName ? `${tMainNamespace}:${screenName}` : `${tMainNamespace}:`

  const t = ((key: string, { nullIfEmpty, ...options }: TOptions & { nullIfEmpty?: boolean } = {}) => {
    const result = _t(key, options)

    if (nullIfEmpty && result === key.split(':').pop()) {
      return null
    }

    return result
  }) as typeof _t

  return {
    t,
    tComponents: (key?: string, options?: TOptions) => t(safeConcat(tComponentPrefix, key), options),
    tError: (key: string, options?: TOptions) => t(safeConcat(tErrorNamespace, key), options),
    tBase: (key?: string, options?: TOptions) => t(safeConcat(tCommonPrefix, key), options),
    tShared: (key?: string, options?: TOptions) => t(safeConcat(tSharedPrefix, key), options),
    tOffice: (key?: string, options?: TOptions) => t(safeConcat(tOfficePrefix, key), options),
    tMain: (key?: string, options?: TOptions) => t(safeConcat(tMainPrefix, key), options),
    tPrefix: {
      components: tComponentPrefix,
      base: tCommonPrefix,
      shared: tSharedPrefix,
      office: tOfficePrefix,
      main: tMainPrefix,
    },
    Trans: <T extends string>({ i18nKey, ...props }: TransProps<T>) => (
      <Trans i18nKey={safeConcat(tCommonPrefix, i18nKey as string)} {...props} />
    ),
    SharedTrans: <T extends string>({ i18nKey, ...props }: TransProps<T>) => (
      <Trans i18nKey={safeConcat(tSharedPrefix, i18nKey as string)} {...props} />
    ),
    MainTrans: <T extends string>({ i18nKey, ...props }: TransProps<T>) => (
      <Trans i18nKey={safeConcat(tMainPrefix, i18nKey as string)} {...props} />
    ),
    OfficeTrans: <T extends string>({ i18nKey, ...props }: TransProps<T>) => (
      <Trans i18nKey={safeConcat(tOfficePrefix, i18nKey as string)} {...props} />
    ),
  }
}
