import React from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor, makeStyles, padding, theme } from '@guiker/components-core'
import { useTranslation } from '@guiker/i18n'
import { CloseIcon } from '@guiker/icons'
import { AlertCircleIcon, SuccessCheckmarkIcon } from '@guiker/icons'

import { useLayoutContext, useMediaQuery, useModal } from '../../../hooks'
import { flattenChildren } from '../../../utils'
import { Collapse, DisplayBody, PBold, PSmall } from '../../Data Display'
import { CircularProgress, RotatingIcon } from '../../Feedback/'
import { TextButton } from '../../Inputs'
import { Flex } from '../../Layout'
import { useNoteContext } from './NoteContext'

export type NoteProps = React.PropsWithChildren & {
  actions?: React.ReactNode
  className?: string
  type?: 'closable' | 'collapsible' | 'none'
  isCollapsed?: boolean
  condensed?: boolean
  statusColor?: AnyColor
  title?: string
  icon?: React.ReactNode | 'success' | 'warning' | 'loading'
  children?: React.ReactNode
  mb?: number
  mt?: number
  openAsModalOnMobile?: boolean
}

const useStyles = makeStyles(
  {
    root: ({ condensed, statusColor, mt, mb }: NoteProps) => ({
      background: theme.palette.getColor(statusColor, 'background'),
      padding: padding(condensed ? 1 : 2, 3),
      boxShadow: 'none',
      borderStyle: condensed ? 'solid' : 'none',
      borderWidth: condensed ? 1 : 0,
      borderColor: theme.palette.getColor(statusColor),
      marginTop: theme.spacing(mt),
      marginBottom: theme.spacing(mb),
    }),
    actions: {
      paddingRight: theme.spacing(3),
    },
    content: {
      paddingRight: theme.spacing(3),
    },
    icon: ({ condensed }: NoteProps) => ({
      height: condensed ? theme.typography.variants.bodySmall.lineHeight : theme.typography.variants.body.lineHeight,
      display: 'flex',
      alignItems: 'center',
      paddingBottom: 3,
    }),
    mainIcon: ({ statusColor }: NoteProps) => ({
      color: theme.palette.getColor(statusColor, 'main'),
    }),
    title: {
      flex: 1,
    },
    text: ({ condensed }: NoteProps) => ({
      fontSize: condensed ? theme.typography.variants.bodySmall.fontSize : theme.typography.variants.body.fontSize,
    }),
    align: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  { name: 'Note' },
)

const Note: React.FC<NoteProps> = ({
  actions,
  className,
  type = 'none',
  condensed,
  statusColor = 'alabaster',
  isCollapsed = false,
  openAsModalOnMobile,
  icon,
  title,
  mb = 0,
  mt = 0,
  children,
}) => {
  const { handleNote } = useNoteContext()
  const classes = useStyles({ statusColor, closable: type === 'closable', mb, mt, condensed })
  const { isAtLeastMidSizeDesktop } = useLayoutContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation('common')
  const { isOpen, closeModal } = useModal(true)
  const { isOpen: isCollapseOpen, closeModal: closeCollapse, openModal: openCollapse } = useModal(!isCollapsed)

  const iconComponent =
    icon === 'success' ? (
      <SuccessCheckmarkIcon size='small' width={24} height={24} />
    ) : icon === 'warning' ? (
      <AlertCircleIcon size='small' />
    ) : icon === 'loading' ? (
      <CircularProgress variant='indeterminate' size={16} color='inherit' />
    ) : (
      icon
    )

  const hasOnlyActions = !children && !!actions
  const shouldDisplayActionsOnTop = hasOnlyActions && type !== 'closable' && isAtLeastMidSizeDesktop

  const actionsGroup = actions && (
    <Flex justifyContent='flex-end' gap={2} className={classes.actions}>
      {flattenChildren(actions)}
    </Flex>
  )

  const content = (
    <Flex flexDirection='column' gap={condensed ? 1 : 2} className={clsx(classes.root, className)}>
      {title && (
        <Flex flexDirection={isMobile ? 'column' : 'row'}>
          <Flex gap={1.5} flex={1}>
            {iconComponent && <span className={clsx(classes.icon, classes.mainIcon)}>{iconComponent}</span>}
            <PBold mb={0} className={clsx(classes.text, classes.title, classes.align)}>
              {title}
            </PBold>
            {type === 'closable' && (
              <span className={clsx(classes.icon)}>
                <CloseIcon
                  size='small'
                  onClick={() => closeModal()}
                  height={condensed ? 8 : 12}
                  width={condensed ? 8 : 12}
                />
              </span>
            )}
            {type === 'collapsible' && (
              <Flex
                onClick={() =>
                  !!openAsModalOnMobile && isMobile && handleNote
                    ? handleNote({ title, content: children })
                    : isCollapseOpen
                    ? closeCollapse()
                    : openCollapse()
                }
                gap={2}
                alignItems='center'
                justifyContent={isMobile ? 'flex-end' : undefined}
              >
                <TextButton size='small'>
                  <PSmall mb={0}>{t(`actions.${isCollapseOpen ? 'hide' : 'readMore'}`)}</PSmall>
                </TextButton>
                <RotatingIcon isRotated={!isCollapseOpen} size='smaller' />
              </Flex>
            )}
          </Flex>
          {shouldDisplayActionsOnTop && actionsGroup}
        </Flex>
      )}
      {(!!openAsModalOnMobile && isMobile) || !children ? (
        <></>
      ) : (
        <Collapse in={(type === 'collapsible' && isCollapseOpen) || type !== 'collapsible'} unmountOnExit={true}>
          <div className={classes.content}>
            {typeof children === 'string' ? (
              <DisplayBody mb={0} className={classes.text}>
                {children}
              </DisplayBody>
            ) : (
              <div>{children}</div>
            )}
            {!title && type === 'closable' && (
              <CloseIcon
                size='small'
                height={condensed ? 8 : 12}
                width={condensed ? 8 : 12}
                onClick={() => closeModal()}
              />
            )}
          </div>
        </Collapse>
      )}

      {!shouldDisplayActionsOnTop && actionsGroup}
    </Flex>
  )

  if (type !== 'closable') {
    return content
  }

  return (
    <Collapse in={isOpen} unmountOnExit={true}>
      {content}
    </Collapse>
  )
}

export { Note }
