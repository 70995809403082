import React, { Context, createContext, useContext, useState } from 'react'

import {
  FieldValues,
  FormProvider as BaseFormProvider,
  FormProviderProps as BaseFormProviderProps,
  useFormContext as baseUseFormContext,
  UseFormReturn,
} from 'react-hook-form'

import { yup } from '@guiker/shared-framework'

import { BeforeValidation } from './types'

type FormProviderProps<T extends FieldValues> = BaseFormProviderProps<T> & {
  beforeValidation?: BeforeValidation<T>
  schema?: yup.Schema<T>
}

type BaseFormContext<T extends FieldValues> = {
  readOnly: boolean
  schema?: yup.Schema<T>
  beforeValidation?: BeforeValidation<T>
  registerBeforeValidation: (beforeValidation: BeforeValidation<T>) => void
}

export type FormContext<T extends FieldValues> = BaseFormContext<T> & UseFormReturn<T, any>

const FormContext = createContext(null)

export const FormProvider = <T extends FieldValues>({
  children,
  beforeValidation: beforeValidationProps,
  schema,
  ...methods
}: FormProviderProps<T>) => {
  const [beforeValidation, setBeforeValidation] = useState<BeforeValidation<T>>(() => beforeValidationProps)

  const registerBeforeValidation = (beforeValidation: BeforeValidation<T>) => {
    setBeforeValidation(() => beforeValidation)
  }

  const value = {
    schema,
    beforeValidation,
    registerBeforeValidation,
  }

  return (
    <FormContext.Provider value={value}>
      <BaseFormProvider {...methods}>{children}</BaseFormProvider>
    </FormContext.Provider>
  )
}

export const useFormContext = <T extends FieldValues>() => {
  const baseContext = baseUseFormContext<T>()

  if (baseContext) {
    return {
      ...baseContext,
      ...useContext<FormContext<T>>(FormContext as Context<FormContext<T>>),
    }
  }
}
