import React from 'react'

import { RadioGroup as CLRadioGroup } from '@guiker/components-library'
import { FieldPath, useFormContext } from '@guiker/react-hook-form'

import { RadioGroupProps as RHFRadioGroupProps, RHFRadioGroup } from './RHFRadioGroup'

export type RadioGroupProps<T extends object = any, P extends FieldPath<T> = any> = RHFRadioGroupProps<T, P>

export const RadioGroup = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue: defaultValueProp,
  readOnly: readOnlyProps,
  ...props
}: RadioGroupProps<T, P>) => {
  const { readOnly: readOnlyForm, watch } = useFormContext()

  if (readOnlyForm) {
    const defaultValue = defaultValueProp || watch(props.name)

    return <CLRadioGroup {...props} defaultValue={defaultValue} />
  } else {
    return <RHFRadioGroup defaultValue={defaultValueProp} readOnly={readOnlyProps} {...props} />
  }
}
