import React from 'react'

import { Checkbox, CheckboxProps as CLCheckboxProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

type AdornmentPosition = 'start' | 'end'

export type RHFCheckboxProps<T extends object, P extends FieldPath<T>> = Omit<
  CLCheckboxProps,
  'defaultChecked' | 'checked' | 'name' | 'defaultValue'
> & {
  adornmentPosition?: AdornmentPosition
  adornmentClassName?: string
  name: P
  defaultValue?: PathValue<T, P>
}

export const RHFCheckbox = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  onChange,
  name,
  required: propsRequired,
  ...props
}: RHFCheckboxProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorMessage, required } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={(defaultValue ?? false) as any}
      render={({ field: { onChange: onChangeRenderProps, onBlur, value, ref }, fieldState }) => {
        return (
          <Checkbox
            name={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange && onChange(e, e.target.checked)
              onChangeRenderProps(e.target.checked)
            }}
            checked={JSON.parse(`${value}`) || false}
            value={value}
            onBlur={onBlur}
            error={!!fieldState.error}
            errorMessage={fieldState.error && errorMessage}
            inputRef={ref}
            required={propsRequired ?? required}
            {...props}
          />
        )
      }}
    />
  )
}
