import React from 'react'

import { clsx } from '@guiker/clsx'
import {
  FlexAlign,
  isAtMostMidSizeDesktop,
  isAtMostTablette,
  isExtraLargeScreenDesktop,
  isLargeScreenDesktop,
  isMobile,
  makeStyles,
  MinWidth,
  Theme,
  Width,
} from '@guiker/components-core'
import { isNumber, merge } from '@guiker/lodash'

import { flattenChildren } from '../../../utils'
import { ColumnGridItem, GridColumn, parseGridColumn as baseParseGridColumn } from '../ColumnGridItem'

type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
type GridTemplateColumns = (number | string)[] | number

const MediaQueryMap = {
  xs: isMobile,
  sm: isAtMostTablette,
  md: isAtMostMidSizeDesktop,
  lg: isLargeScreenDesktop,
  xl: isExtraLargeScreenDesktop,
} as const

type SpacingValue = number

type Properties = {
  gap?: SpacingValue
  gridColumn?: GridColumn
  gridTemplateColumns?: GridTemplateColumns
}

type PerSizeConfig = Partial<Record<Sizes, Properties>>
type PerSizeColumn = Partial<Record<Sizes, GridColumn>>

export type ColumnGridLayoutProps = React.PropsWithChildren & {
  minWidth?: MinWidth
  className?: string
  justifyItems?: FlexAlign
  alignItems?: FlexAlign
  perItemGridColumn?: Record<number, PerSizeColumn>
} & Properties &
  PerSizeConfig

const parsePerSize = <CssPropertyKey extends keyof Properties, CssPropertyValue>(
  key: CssPropertyKey,
  values: ColumnGridLayoutProps,
  parser: (v: Properties) => CssPropertyValue,
) => {
  const defaultProperty = values[key] || (values['md'] && values['md'][key])
  const properties: Record<string, CssPropertyValue | Record<string, CssPropertyValue>> = {}
  const defaultValue = parser({ [key]: defaultProperty } as any)

  if (defaultValue) {
    properties[key] = defaultValue
  }

  const { xs, sm, md, lg, xl } = values

  Object.entries({ xl, lg, md, sm, xs }).map(([size, value]: [Sizes, Properties]) => {
    if (!value) {
      return
    }

    const parsedCssValue = parser(value)

    if (!parsedCssValue) return

    properties[`${MediaQueryMap[size]}`] = {
      [key]: parsedCssValue,
    }
  })

  return properties
}

const parseGridTemplate = (value: GridTemplateColumns, minWidth: Width = 0) => {
  if (!value) return

  if (isNumber(value)) {
    return `repeat(${value}, minmax(${minWidth}px, 1fr))`
  }

  return value.map((v) => (isNumber(v) ? `${v}fr` : v)).join(' ')
}

const parseGridTemplateColumns = (values: ColumnGridLayoutProps, minWidth: MinWidth) => {
  return parsePerSize('gridTemplateColumns', values, (v) => parseGridTemplate(v.gridTemplateColumns, minWidth))
}

const parseGridColumn = (values: ColumnGridLayoutProps) => {
  return parsePerSize('gridColumn', values, (v) => baseParseGridColumn(v?.gridColumn, false))
}

const parseGap = (values: ColumnGridLayoutProps, theme: Theme) => {
  return parsePerSize('gap', values, (v) => theme.spacing(v?.gap))
}

const useGridStyles = makeStyles(
  (theme) => ({
    root: (props: Omit<ColumnGridLayoutProps, 'perItemGridColumn'>) =>
      merge(
        {
          display: 'grid',
          width: '100%',
          justifyItems: props.justifyItems,
          alignItems: props.alignItems,
          '& > *': {
            ...parseGridColumn(props),
          },
        },
        parseGap(props, theme),
        parseGridTemplateColumns(props, props.minWidth),
      ),
  }),
  { name: 'ColumnGridLayout' },
)

const ColumnGridLayout: React.FC<ColumnGridLayoutProps> = ({
  children,
  className,
  perItemGridColumn = {},
  gap = 4,
  ...props
}) => {
  const classes = useGridStyles({ gap, ...props })

  return (
    <div className={clsx(className, classes.root)}>
      {flattenChildren(children).map((child, index) => {
        if (!perItemGridColumn[index]) {
          return child
        }

        return (
          <ColumnGridItem key={index} {...perItemGridColumn[index]}>
            {child}
          </ColumnGridItem>
        )
      })}
    </div>
  )
}

export { ColumnGridLayout }
