import React from 'react'

import { isMobile, makeStyles } from '@guiker/components-core'
import { math } from '@guiker/lodash'

import {
  ButtonContainer as BaseButtonContainer,
  ButtonContainerProps as BaseButtonContainerProps,
  ButtonProps,
  ButtonWithLoader,
  SecondaryButton,
} from '../..'
import { Inline } from '../../Data Display'
import { Flex } from '../../Layout'

const useStyle = makeStyles(
  {
    progression: {
      minWidth: 40,
    },
    nextButton: {
      [isMobile]: {
        flexGrow: 4,
      },
    },
  },
  { name: 'ButtonContainer' },
)

type ProgressionProps = {
  total: number
  current: number
  format: '%' | '#/#'
}

export type ButtonContainerProps = BaseButtonContainerProps &
  BackButtonProps &
  NextButtonProps & {
    hasBackButton?: boolean
    hasNextButton?: boolean
    nextButtonType?: 'button' | 'reset' | 'submit'
    hasDrawerNav?: boolean
    isDrawerMinimized?: boolean
    progression?: ProgressionProps
  }

type BackButtonProps = ButtonProps & {
  backLabel?: string
  onClickBack?: () => void
  isSubmitting?: boolean
}

type NextButtonProps = ButtonProps & {
  nextDisabled?: boolean
  nextLabel?: string
  onClickNext?: (args?: unknown) => void
  isSubmitting?: boolean
  errors?: { [x in string]: any }
}

const BackButton: React.FC<BackButtonProps> = ({ isSubmitting, onClickBack, backLabel }) => {
  return (
    <SecondaryButton disabled={isSubmitting} onClick={onClickBack}>
      {backLabel}
    </SecondaryButton>
  )
}

const NextButton: React.FC<NextButtonProps> = ({ nextDisabled, isSubmitting, onClickNext, nextLabel, errors }) => {
  const classes = useStyle()

  return (
    <ButtonWithLoader
      className={classes.nextButton}
      disabled={nextDisabled || isSubmitting}
      isLoading={isSubmitting}
      onClick={onClickNext}
      errors={errors}
    >
      {nextLabel}
    </ButtonWithLoader>
  )
}

const Progression: React.FC<ProgressionProps> = ({ format, current, total }) => {
  const classes = useStyle()

  const data = format === '%' ? `${math.decimal.round((current / total) * 100, 0)}%` : `${current} / ${total}`

  return (
    <Flex className={classes.progression} alignItems='center'>
      <Inline mb={0} color='textPrimary'>
        {data}
      </Inline>
    </Flex>
  )
}

const ButtonContainer: React.FC<ButtonContainerProps> = ({
  hasBackButton = true,
  hasNextButton = true,
  hasDrawerNav = false,
  isDrawerMinimized,
  backLabel,
  onClickBack,
  nextDisabled,
  nextButtonType = 'submit',
  nextLabel,
  onClickNext,
  isSubmitting,
  progression,
  errors,
}) => {
  return (
    <BaseButtonContainer hasDrawerNav={hasDrawerNav} isDrawerMinimized={isDrawerMinimized}>
      {progression && <Progression {...progression} />}
      {hasBackButton && <BackButton backLabel={backLabel} onClickBack={onClickBack} isSubmitting={isSubmitting} />}
      {hasNextButton && (
        <NextButton
          type={nextButtonType}
          nextLabel={nextLabel}
          nextDisabled={nextDisabled}
          onClickNext={onClickNext}
          isSubmitting={isSubmitting}
          errors={errors}
        />
      )}
    </BaseButtonContainer>
  )
}

export { ButtonContainer }
