import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, margin } from '@guiker/components-core'
import { isArray } from '@guiker/shared-framework'
import { MenuProps } from '@material-ui/core/Menu'
import { default as MenuItem } from '@material-ui/core/MenuItem'
import { default as Select } from '@material-ui/core/Select'

import { Input, InputProps } from '../Input'
import { InputBase, InputBaseProps } from '../InputBase'

export type DropdownProps = InputProps &
  InputBaseProps & {
    options: {
      label: string | React.ReactNode
      value: string
      startAdornment?: React.ReactNode
      endAdornment?: React.ReactNode
    }[]
    maxWidth?: number | `${number}%`
    menuMaxWidth?: number | `${number}%`
    maxHeight?: number | `${number}%`
    menuProps?: Partial<MenuProps>
    multiple?: boolean
  }

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 'initial !important',
      width: '100%',
      maxWidth: ({ maxWidth }: { maxWidth: string | number }) => (maxWidth ? maxWidth : undefined),
    },
    select: {
      cursor: ({ disabled, readOnly }: { readOnly: boolean; disabled: boolean }) =>
        readOnly || disabled ? 'text' : 'pointer',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    input: {
      marginRight: theme.spacing(1),
    },
    menuItem: {
      gap: theme.spacing(4),
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    fullSizedMenuItem: {
      ...theme.typography.variants.body,
      minHeight: 60,
    },
    condensedMenuItem: {
      ...theme.typography.variants.bodySmall,
      minHeight: 40,
    },
    selectMenu: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > .MuiPaper-root': {
        borderRadius: 0,
        borderWidth: 2,
        boxShadow: theme.shadows.medium,
        marginTop: ({ condensed }: { condensed: boolean }) => (condensed ? 0 : theme.spacing(0.5)),
        marginBottom: ({ condensed }: { condensed: boolean }) => (condensed ? 0 : theme.spacing(0.5)),
        maxHeight: ({ maxHeight }: { maxHeight: string | number }) =>
          maxHeight ? `${maxHeight} !important` : undefined,
        minWidth: 'initial !important',
        width: '100%',
        maxWidth: ({ menuMaxWidth }: { menuMaxWidth: string | number }) => (menuMaxWidth ? menuMaxWidth : undefined),
        '& > .MuiList-root': ({ condensed }: { condensed: boolean }) => ({
          margin: condensed ? 0 : margin(0, 0.5),
          padding: 0,
          '& > .MuiListItem-root': {
            ...(condensed ? theme.typography.variants.bodySmall : theme.typography.variants.body),
            padding: theme.spacing(2),
            '& > .MuiTouchRipple-root': {
              display: 'none',
            },
          },
          '& > .Mui-selected': {
            backgroundColor: theme.palette.grey[5],
          },
        }),
      },
    },
    icon: {
      marginRight: theme.spacing(1),
      display: ({ readOnly }: { readOnly: boolean }) => (readOnly ? 'none' : 'inline'),
    },
  }),
  { name: 'Dropdown' },
)

const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    className,
    defaultValue,
    disabled,
    condensed,
    helperText,
    id,
    label,
    labelClassName,
    name,
    required,
    value,
    maxWidth,
    menuMaxWidth = 360,
    maxHeight,
    menuProps: { className: menuClassName, ...menuProps } = {},
    options = [],
    readOnly,
    multiple = false,
    ...others
  } = props
  const { errorMessage, ...inputProps } = props

  const classes = useStyles({
    condensed,
    disabled,
    maxWidth: props.fullWidth ? '100%' : maxWidth,
    menuMaxWidth,
    maxHeight,
    readOnly,
  })

  return (
    <Input
      className={clsx(className, classes.root)}
      disabled={disabled}
      errorMessage={errorMessage}
      helperText={helperText}
      label={label}
      labelClassName={labelClassName}
      name={name}
      id={id}
      required={required}
      readOnly={readOnly}
      condensed={condensed}
      {...others}
    >
      <Select
        labelId={id}
        id={id}
        defaultValue={defaultValue ?? undefined}
        value={
          defaultValue != null
            ? multiple
              ? []
              : undefined
            : multiple
            ? isArray(value)
              ? value
              : !value
              ? []
              : [value]
            : value
        }
        input={<InputBase {...inputProps} />}
        classes={{
          root: classes.select,
          icon: classes.icon,
          selectMenu: classes.selectMenu,
        }}
        multiple={multiple}
        inputProps={{ className: classes.input }}
        MenuProps={{
          className: clsx(classes.selectMenu, menuClassName),
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          ...menuProps,
        }}
        fullWidth
      >
        {options.map((option) => (
          <MenuItem
            className={clsx(classes.menuItem, {
              [classes.condensedMenuItem]: condensed,
              [classes.fullSizedMenuItem]: !condensed,
            })}
            key={option.value}
            value={option.value}
          >
            {option.startAdornment}
            {option.label}
            {option.endAdornment}
          </MenuItem>
        ))}
      </Select>
    </Input>
  )
}

export { Dropdown }
