import React from 'react'

import { CheckboxGroup, CheckboxGroupProps as CLCheckboxGroupProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type RHFCheckboxGroupProps<T extends object, P extends FieldPath<T>> = Omit<
  CLCheckboxGroupProps,
  'name' | 'defaultValue'
> & {
  name: P
  defaultValue?: PathValue<T, P>
}

export const RHFCheckboxGroup = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  onChange,
  name,
  required: propsRequired,
  ...props
}: RHFCheckboxGroupProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorMessage, required } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={(defaultValue ?? false) as any}
      render={({ field: { onChange: onChangeRenderProps, value, ref }, fieldState }) => {
        return (
          <CheckboxGroup
            name={name}
            onChange={(values: string[]) => {
              onChange?.(values)
              onChangeRenderProps(values)
            }}
            value={value}
            error={!!fieldState.error}
            errorMessage={fieldState.error && errorMessage}
            inputRef={ref}
            required={propsRequired ?? required}
            {...props}
          />
        )
      }}
    />
  )
}
