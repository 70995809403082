import React from 'react'

import { CheckboxGroup as CLCheckboxGroup } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { RHFCheckboxGroup, RHFCheckboxGroupProps } from './RHFCheckboxGroup'

export type CheckboxGroupProps<T extends object, P extends FieldPath<T>> = RHFCheckboxGroupProps<T, P>

export const CheckboxGroup = <T extends object = any, P extends FieldPath<T> = any>(
  props: CheckboxGroupProps<T, P>,
) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    return <CLCheckboxGroup {...props} />
  } else {
    return <RHFCheckboxGroup {...props} readOnly={contextReadOnly} />
  }
}
