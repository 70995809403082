import React from 'react'

import { clsx } from '@guiker/clsx'
import { lighten, makeStyles, theme, toPx } from '@guiker/components-core'
import { default as MUIInputBase, InputBaseProps as MUIInputBaseProps } from '@material-ui/core/InputBase'

export type InputBaseProps = React.PropsWithChildren &
  Omit<MUIInputBaseProps, 'color'> & {
    condensed?: boolean
    maxWidth?: number | string
    required?: boolean
    mb?: number
    ml?: number
    mr?: number
    mt?: number
  }

const useStyles = makeStyles(
  {
    root: {
      height: 'initial',
      maxHeight: 'initial',
      width: 'initial',
      maxWidth: 'initial',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[5],
      borderColor: 'transparent',
      borderStyle: 'solid',
      borderWidth: 2,
      boxSizing: 'border-box',
      color: theme.palette.grey[60],
      display: 'flex',
      flexGrow: 1,
      gap: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.grey[10],
      },
      '&:active, &:focus-within': {
        borderColor: theme.palette.primary.main,
      },
      ...theme.typography.variants.body,
      border: 'none',
      outline: 'none',
    },
    input: {
      padding: `calc(${theme.spacing(2)}px - 2px)`,
      height: 'initial',
      lineHeight: toPx(24),
    },
    condensedInput: {
      padding: `calc(${theme.spacing(1)}px - 2px)`,
      fontSize: theme.typography.variants.bodySmall.fontSize,
    },
    condensedAdornment: {
      fontSize: theme.typography.variants.bodySmall.fontSize,
    },
    readOnly: {
      backgroundColor: 'transparent',
      border: 'none',
      color: theme.palette.primary.main,
      '&:active, &:focus-within, &:hover': {
        backgroundColor: 'transparent',
      },
    },
    readOnlyInput: {
      fontWeight: theme.typography.variants.bodyBold.fontWeight,
    },
    error: {
      borderColor: lighten(theme.palette.status.alert.main, 0.45),
      backgroundColor: lighten(theme.palette.status.alert.background, 0.65),
      '&:active, &:focus-within, &:hover': {
        backgroundColor: lighten(theme.palette.status.alert.background, 0.6),
      },
    },
    disabled: {
      opacity: 0.8,
      '&:active, &:focus-within, &:hover': {
        backgroundColor: theme.palette.grey[5],
        borderColor: 'transparent',
      },
      '& > .Mui-disabled': {
        color: `${theme.palette.grey[60]}`,
        cursor: 'not-allowed',
      },
    },
    adornment: {
      paddingRight: theme.spacing(1),
    },
    multiline: { padding: 0 },
  },
  { name: 'InputBase' },
)

const InputBase: React.FC<InputBaseProps> = (props) => {
  const { condensed, disabled, error, name, readOnly, defaultValue, value, endAdornment, startAdornment } = props
  const classes = useStyles()

  const adornmentClassName = clsx(classes.adornment, {
    [classes.disabled]: disabled,
    [classes.readOnly]: readOnly,
    [classes.condensedAdornment]: condensed,
  })

  const isControlled = defaultValue === undefined

  return (
    <MUIInputBase
      inputProps={{
        'aria-label': name,
      }}
      classes={{
        root: clsx(classes.root, {
          [classes.disabled]: disabled,
          [classes.error]: error,
          [classes.readOnly]: readOnly,
        }),
        input: clsx(classes.input, { [classes.readOnlyInput]: readOnly, [classes.condensedInput]: condensed }),
        ...(endAdornment && {
          adornedEnd: adornmentClassName,
          inputAdornedEnd: adornmentClassName,
        }),
        ...(startAdornment && {
          adornedStart: adornmentClassName,
          inputAdornedStart: adornmentClassName,
        }),
        multiline: classes.multiline,
      }}
      value={isControlled ? value || '' : undefined}
      defaultValue={defaultValue}
      {...props}
    />
  )
}

export { InputBase }
