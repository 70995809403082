import React, { Context, createContext, useContext } from 'react'

import {
  Control,
  FieldError,
  FieldNamesMarkedBoolean,
  FieldValues,
  FormState,
  UseFormClearErrors,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormRegisterReturn,
  UseFormReset,
  UseFormResetField,
  UseFormReturn,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form'

import { renderChildren } from '@guiker/react-utils'
import { get, isArray, yup } from '@guiker/shared-framework'

import { BeforeValidation } from '../types'

type ReadOnlyFormProps<T extends FieldValues> = React.PropsWithChildren & {
  readOnly?: boolean
  defaultValues?: T
  schema?: yup.Schema<unknown>
}

type ReadOnlyFormContext<T extends FieldValues> = UseFormReturn<T, any> & {
  registerBeforeValidation: (beforeValidation: BeforeValidation<T>) => void
}

const ReadOnlyFormContext = createContext<ReadOnlyFormContext<unknown>>(null)

const defaultState = {
  invalid: false,
  isDirty: false,
  isTouched: false,
  error: {} as FieldError,
}

const formState = {
  ...defaultState,
  dirtyFields: {} as FieldNamesMarkedBoolean<any>,
  isSubmitted: false,
  isSubmitSuccessful: false,
  submitCount: 0,
  touchedFields: {} as FieldNamesMarkedBoolean<any>,
  isSubmitting: false,
  isValidating: false,
  isValid: true,
  errors: {} as FieldError,
}

const getter = <T extends FieldValues>(defaultValues: T) => {
  return (name?: string | string[]) => {
    if (!name) {
      return defaultValues
    } else if (isArray(name)) {
      return name.map((n) => get(defaultValues, n, undefined))
    } else {
      return get(defaultValues, name, undefined)
    }
  }
}

export const ReadOnlyForm = <T extends FieldValues>({ children, schema, defaultValues }: ReadOnlyFormProps<T>) => {
  const value = {
    defaultValues,
    schema,
    watch: getter(defaultValues) as UseFormWatch<T>,
    getValues: getter(defaultValues) as UseFormGetValues<T>,
    getFieldState: (() => defaultState) as UseFormGetFieldState<T>,
    setError: (() => {}) as UseFormSetError<T>,
    clearErrors: (() => {}) as UseFormClearErrors<T>,
    setValue: (() => {}) as UseFormSetValue<T>,
    trigger: (() => {}) as UseFormTrigger<T>,
    formState: formState as FormState<T>,
    resetField: (() => {}) as UseFormResetField<T>,
    reset: (() => {}) as UseFormReset<T>,
    handleSubmit: (() => {
      return async () => {}
    }) as UseFormHandleSubmit<T>,
    unregister: (() => {}) as UseFormUnregister<T>,
    registerBeforeValidation: () => {},
    control: {} as Control<T, unknown>,
    register: (() => {
      return {} as UseFormRegisterReturn
    }) as UseFormRegister<T>,
    setFocus: (() => {}) as UseFormSetFocus<T>,
  }

  const ContextProvider = ReadOnlyFormContext.Provider as React.Provider<ReadOnlyFormContext<T>>

  return <ContextProvider value={value}>{renderChildren(children, value)}</ContextProvider>
}

export const useReadOnlyFormContext = <T,>() => {
  return useContext<ReadOnlyFormContext<T>>(ReadOnlyFormContext as Context<ReadOnlyFormContext<T>>)
}
