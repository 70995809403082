import { InputProps } from './Input'

export const extractInputProps = <T extends InputProps>(props: T) => {
  const {
    checked,
    className,
    condensed,
    defaultChecked,
    defaultValue,
    disabled,
    error,
    errorMessage,
    helperText,
    id,
    label,
    name,
    readOnly,
    required,
    value,
    labelAdornment,
    withLabelPlaceholder,
    ...otherProps
  } = props

  return {
    inputProps: {
      checked,
      condensed,
      className,
      defaultChecked,
      defaultValue,
      disabled,
      error,
      errorMessage,
      helperText,
      id,
      label,
      name,
      readOnly,
      required,
      labelAdornment,
      value,
      withLabelPlaceholder,
    },
    otherProps,
  }
}
