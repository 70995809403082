import React from 'react'

import { DatePicker as CLDatePicker, DatePickerProps as CLDatePickerProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, ErrorMessages, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type RHFDatePickerProps<T extends object, P extends FieldPath<T>> = Omit<
  CLDatePickerProps,
  'name' | 'defaultValue' | 'value'
> &
  ErrorMessages & {
    name: P
    defaultValue?: PathValue<T, P>
  }

const RHFDatePicker = <T extends object, P extends FieldPath<T>>({
  name,
  defaultValue,
  errorMessages = {},
  required: propsRequired,
  ...props
}: RHFDatePickerProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorType, errorMessage, required } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, onChange: onChangeRenderProps, ...renderProps }, fieldState }) => (
        <CLDatePicker
          onChange={(event) => {
            onChangeRenderProps(event)
          }}
          error={!!fieldState.error}
          errorMessage={errorMessages[errorType] || (fieldState.error && errorMessage)}
          required={propsRequired ?? required}
          inputRef={ref}
          {...renderProps}
          {...props}
        />
      )}
    />
  )
}

export { RHFDatePicker }
