import { useMemo } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { useClaimsAuthenticationContext } from '@guiker/claims-authentication-context'
import { useConfig } from '@guiker/config-context'
import { ApiClient, ApiClientArguments, RecordRouteDefinition } from '@guiker/rest-client'

const commonArgs = (config: ReturnType<typeof useConfig>) => ({
  baseUrl: config.apiBaseUrl,
  logger: config.debug ? console : undefined,
})

export { RecordRouteDefinition, ApiClient }

export type ApiClientGenerator<T extends RecordRouteDefinition> = (args: ApiClientArguments) => ApiClient<T>

export const usePublicApiClient = <T extends RecordRouteDefinition>(apiClient: ApiClientGenerator<T>) => {
  return () => {
    const config = useConfig()
    const { baseUrl, logger } = commonArgs(config)

    return useMemo(() => apiClient({ baseUrl, logger }), [baseUrl, logger])
  }
}

export const useJwtAuthenticatedApiClient = <T extends RecordRouteDefinition>(apiClient: ApiClientGenerator<T>) => {
  return ({ claims: propsClaims }: { claims?: { token: string } } = {}) => {
    const { claims } = useClaimsAuthenticationContext()
    const config = useConfig()
    const { baseUrl, logger } = commonArgs(config)

    return useMemo(
      () =>
        apiClient({
          baseUrl,
          logger,
          accessToken: propsClaims?.token || (claims?.token as string),
        }),
      [propsClaims, claims, baseUrl, logger],
    )
  }
}

export const useAuthenticatedApiClient = <T extends RecordRouteDefinition>(apiClient: ApiClientGenerator<T>) => {
  return ({ accessToken }: { accessToken?: string } = {}) => {
    const { user } = useAuthenticationContext()
    const config = useConfig()
    const { baseUrl, logger } = commonArgs(config)

    return useMemo(
      () =>
        apiClient({
          baseUrl,
          logger,
          accessToken: accessToken || user?.accessToken,
        }),
      [accessToken, user?.accessToken, baseUrl, logger],
    )
  }
}

export type ApiClientPayload<A extends Record<string, any>, K extends keyof A> = Parameters<A[K]>[0] extends {
  payload: any
}
  ? Parameters<A[K]>[0]['payload']
  : never
